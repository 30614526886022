    .conative-aniversary-loading-container,.aniversary-img-container {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;  
        }
      
    .loader-sparkle-container{
      animation: sparkleblink 2s forwards  linear;
      animation-delay: 1.5s;
      opacity: 0;
    }
    .loader-wave-container{
      animation: waveLoader 7s forwards infinite, forOpactiy 1s forwards linear;
      animation-delay: 1.5s;
      opacity: 0;
    }
    @keyframes forOpactiy {
      0%{
        opacity: 0;
      }100%{
        opacity: 1;

      }
    }
    @keyframes waveLoader {
      0% {
        transform: translateY(-50%) rotate(0deg);
    }
    100% {
        transform: translateY(-50%) rotate(360deg);
    }
    }
    @keyframes sparkleblink {
      0%{
        opacity: 0;
      }20%{
        opacity: 1;
      }40%{
        opacity: 0;
      }60%{
        opacity: 1;
      }80%{
        opacity: 0;
      }100%{
        opacity: 1;
      }
    }

.loader-counter-container .counter-text{
  font-size: 175px;
  line-height: 180px;
}.loader-counter-container .counter-suffix{
  font-size: 85px;
  line-height: 90px;
  animation: th 0.5s forwards;
  animation-delay: 1.8s;
  opacity: 0;
}
.loader-counter-container .counter-suffix, 
.loader-counter-container .counter-text,.counter-sub,.modal-counter-gradient-num{
  font-family: 'gilroy-extraBold';
  background: linear-gradient(295deg, #ffe5a2 5%, #bf841a 50%, #ffcd74 95%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes th {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 100%;
  }
}
.loader-dot{
  position: relative;
}.loader-dot::before{
  height: 12px;
  width: 12px;
  content: "";
  border-radius: 50%;
  background: linear-gradient(295deg, #ffe5a2 5%, #bf841a 50%, #ffcd74 95%);
  display: inline-block;
  position: absolute;
  transform: translate(155px,-130px);
}
  .loader-counter-container {
  position: absolute;
  width: 24%;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0px auto;
  text-align: center;
}.loader-wave-container, .loader-sparkle-container,.loader-outline-container{
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0px auto;
  text-align: center;

}

.counter-sub{
display: block;
  font-size: 52px;
  line-height: 75px;
  width: 100%;
  opacity: 0;
  animation: counter-text-opacity 0.5s 2.5s forwards;
}
.counter-inner-container{
  width: fit-content;
  margin: 0 auto;
} 
@keyframes counter-text-opacity{
  0%{
    opacity: 0;
  }100%{
    opacity: 1;
  }
  
}.loader-counter-container .counter-text, .counter-suffix {
  display: inline-block;
  vertical-align: top;
}.loader-counter-container{
  top: 52%;
}.counter-aniv {
  width:500px;
  height:160px;
  font-family: 'gilroy-extraBold';
  background: linear-gradient(295deg, #ffe5a2 5%, #bf841a 50%, #ffcd74 95%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow:hidden;
}
.numbers{
  width:auto;
  white-space:nowrap;
  animation-timing-function: linear;
  -webkit-animation: countNumber 1s;
  -webkit-animation-fill-mode:forwards;
  -webkit-animation-timing-function: steps(10);
}
.numbers div {
  float:left;
  text-align:center;
  width:500px;
  height:200px;
  font-size: 154px;
  line-height: 200px;
}

@keyframes countNumber {
  0% {
      margin-left:0px
  }
  100% {
      margin-left:-5000px
  }
}