
.header-menu-block ul li {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 0px 0px 80px;
    position: relative;
}
.header-menu-block ul li.header-menu-is-active::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    border-radius: 10px 10px 0px 0px;
    background-color: #f84525;
    bottom: 0;
    left: 0;
    opacity: 1;
}li.header-menu-is-active a{
    color: #f84525 !important;
}

.header-menu-block ul li:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    border-radius: 10px 10px 0px 0px;
    background-color: #f84525;
    bottom: 0;
    left: 0;
    opacity: 0;
}
.header-menu-block ul li.menu-item.children_menu.active-tab a, .header-menu-block ul li a:hover{
    color: #f84525;
}
.header-menu-block ul li.menu-item.children_menu.active-tab:before{
    opacity: 1;
}
.header-menu-block ul li:first-child{
    margin-left: 0;
}
.header-menu-block ul li a, .submenu li a {
    color: #040201;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
}
.header-menu-block ul > li >a {
    position: relative;
    padding-bottom: 45px;
}
.header-top-wrapper{
    padding: 24px 0px 0px;
    background-color: #fbfbfb;
}
.header-logo-block {
    width: 15%;
}
.header-menu-block {
    width: 63%;
    margin: 22px 0px 0px 116px;
}
.menu-close-icon{
    width: 10%;
    margin: 15px 0px;
    text-align: right;
}
.menu-close-icon span{
    font-size: 22px;
    line-height: 30px;
    color: #f84525;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-family: 'Font Awesome 5 Free';

}
.header-bottom-info-wrap h3, .header-bottom-info-wrap a {
    display: inline-block;
    vertical-align: middle;
}
.header-bottom-info-wrap h3 {
    width: 78%;
    font-size: 34px;
    line-height: 45px;
    color: #040201;
}
.header-bottom-info-wrap a {
    width: 22%;
    margin-left: 0%;
}
.header-bottom-info-wrap h3 strong {
    font-weight: normal;
}
.header-bottom-info-wrap {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid #eeeeef;
}
.header-contact-information .header-contact-left, .header-contact-information .social-icon-form-left {
    width: 50%;
}
.header-contact-information .contact-details-block span {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    display: block;
}
.header-contact-information .contact-details-block:first-child{
    margin-left: 0;
}
.header-contact-information .contact-details-block{
    display: inline-block;
    vertical-align: top;
    margin-left: 100px;
}
.header-contact-information .contact-details-block a{
    font-size: 20px;
    line-height: 25px;
    margin-top: 20px;
    color: #040201;
    font-weight: 700;
}
.header-contact-information .contact-details-block .hr_mail {
    margin-left: 30px;
}
.header-contact-information .contact-details-block a:hover{
    color: #f24335;
}
.header-contact-information .contact-social-icon-list li {
    margin-left: 15px;
}
.header-contact-information .contact-social-icon-list li a{
    width: 60px;
    height: 60px;
}
.header-contact-information .social-icon-form-left {
    position: relative;
    text-align: right;
}
.header-contact-information  .contact-social-icon-list li a:hover svg path,
.header-contact-information  .contact-social-icon-list li a:hover svg rect{
    fill :#040201 !important
}
.header-contact-information .social-icon-form-left svg, .submenu li a > span img{
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.header-contact-information .social-icon-form-left svg path {
    fill: #000 !important;
    transition: 0.6s;
}
.header-contact-information  .contact-social-icon-list li a:hover{
    background-color: #fff;
}
.header-bottom-wrapper {
    background-color: #f4f5f6;
    padding-top: 77px;
    position: relative;
    z-index: 1;
}
.header-bottom-wrapper .center-wrapper{
    max-width: 1364px;
    position: relative;
    padding-bottom: 70px;
}
.header-bottom-wrapper .center-wrapper:before{
    content: '';
    width: 120%;
    height: 10px;
    bottom: 0;
    position: absolute;
    left: -104px;
    right: 0;
    margin: 0px auto;
    background-color: #f84525;
}
ul.submenu {
    /* width: 72%; */
    margin: 20px auto 20px;
}
.submenu li {
    width: 31%;
    padding-right: 10px;
    margin: 0 0px 40px;
    display: inline-block;
    vertical-align: top;
}
.submenu li a {
    display: block;
    width: 100%;
    overflow: hidden;
}
.submenu li a > span {
    display: inline-block;
    vertical-align: top;
    margin-left: 21px;
}
.submenu li a > span:first-child{
    margin-left: 0;
    width: 57px;
    height: 57px;
    background-color: #ececec;
    position: relative;
}
span.header-submenu-content p {
    font-size: 13px;
    line-height: 20px;
    color: #a2a1a2;
    font-weight: normal;
    text-transform: initial;
}
 .submenu li a span.header-submenu-content a{
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    text-transform: capitalize;
    font-weight: 600;
    padding-right: 30px;
    position: relative;
    display: inline-block;
    width: auto;
}
 .submenu li a span.header-submenu-content a:before{
    content: '';
    width: 15px;
    height: 2px;
    background-color: #f84525;
    right: 0;
    top:50%;
    transform: translateY(-50%);
    position:absolute;
}
 .submenu li a > span.submenu-text-wr {
    width: 81%;
}
 .submenu li a > span.submenu-text-wr span.header-submenu-content {
    transform: translateY(33px);
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}
 .submenu li a > span.submenu-text-wr span.submenu-text {
    transform: translateY(16px);
    display: block;
    transition: 0.5s;
}
 .submenu li a:hover > span.submenu-text-wr span.submenu-text, .submenu li a:hover > span.submenu-text-wr span.header-submenu-content {
    transform: translateY(0px);
}
 .submenu li a:hover > span.submenu-text-wr span.header-submenu-content{
    opacity: 1;
    visibility: visible;
}
 .submenu li a:hover > span:first-child {
    background-color: #fff;
    box-shadow: 0px 8px 13px rgb(0 0 0 / 15%);
    border-radius: 5px;
    transition: 0.5s;
}
.header-conative-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0px auto;
    z-index: -1;
}
.header-conative-title h3 {
    -webkit-text-stroke: rgba(112, 112, 112,0.18);
    font-size: 239px;
    line-height: 262px;
    color: transparent;
    -webkit-text-stroke-width: 0.6px;
    text-align: center;
}
.r-tabs .r-tabs-nav {
    margin: 0;
    padding: 0;
}
.submenu-wrapper{
    position: relative;
    z-index: 1;
}
.accordion-wrapper .accordion-head{
    display: none;
}