.holi-strip {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ff4081;
    color: white;
    text-align: center;
    padding: 10px;
    z-index: 1000;
    transition: transform 0.5s ease-in-out;
    padding: 0px 10px;
}

.holi-strip.hidden {
    transform: translateY(100%);
}

.holi-strip.visible {
    transform: translateY(0);
}

.marquee-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.holi-colors-img img {
    margin-left: 5px;
    width: clamp(30px, 5vw, 40px);
    height: clamp(30px, 5vw, 40px);
    animation: rotateImage 1s ease-in-out infinite alternate;
}

@keyframes rotateImage {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(20deg);
    }
}

.rotate-animation {
    animation: rotateImage 1s ease-in-out infinite alternate;
}

.btn {
    color: #ff4081;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
}

.btn:hover {
    background: #fff5f8;
}