.loader-video-block {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0px auto;
    width: 100%;
    z-index: 999;
}
.loader-video-block video {
  width: 100%;
  height: 100%;
}
#loadingdiv {
  background-color: #000;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
}