@media only screen and (max-width: 1919px) {
    /* Home Page */
    .banner-image-blk {
	    width: 55%;
	    right: -45px;
	}
	.banner-image-blk video, .banner-image-blk img {
	    background-color: #fbfbfb;
	    width: 100%;
	}.banner-img-blk.aniversary img{
		width: auto;
	}
	.get-started-content {
    	padding: 95px 137px 82px;
	}
	.header-contact-content {
		width: 67%;
	}
}/*1749 */
@media only screen and (max-width: 1750px){
	/* Home Page */
	.circle {
		width: 326px;
		height: 326px;
	}
	.circle h1 {
		font-size: 53px;
		line-height: 76px;
		margin: 91px 0px;
	}
    .banner-image-blk {
	    width: 48%;
	    right: -18px;
	    top: 0;
	}
	.banner-image-blk.right.aniversary{
		width: 55%;
	}
	.diwali-modal{
		max-height:104vh;
	}
	.common-heading span:nth-child(2) {
	    font-size: 62px;
	    line-height: 70px;
	}
	.web, .seges, .developer {
	    font-size: 31px;
	    line-height: 38px;
	}
	.seges{left: 30px;}
	.developer {
    	right: 51px;
	}
	.testimonial-post-blk{
		width:auto;
		/* margin-left: 40px; */
	}
    /*Our Team*/
	.con-team-content-wr {
	    padding: 73px 0px 88px;
	}
	.con-team-content.con-overlay-title {
	    margin: -198px auto 0px 23%;
	    width: 77%;
	}
	.con-team-contact-left .common-heading h3 span:nth-child(2), .con-team-contact-left .common-heading h3 span:nth-child(2) span {
	    font-size: 41px;
	    line-height: 52px;
	}
	.con-team-content h2 {
	    font-size: 42px;
	    line-height: 45px;
	}
	.con-team-content-wr .con-about-img {
    	width: 100%;
	}
	/*services pages*/
	.con-seo-content, .con-six-box-blk, .con-left-right-wr, .con-web-develop-design-wr .con-anim-left-right-wr, .con-ecom-services-inner-wr {
    	width: 92%;
	}
	/* industries we serve */
	.company-branding-wr .testimonial-head h3 {
		font-size: 272px;
		line-height: 325px;
	}
	/* industries we serve */
	.mockup-banner-img {
		width: 50%;
	}
	.con-industries-wr .con-icon-title-wr {
		padding-top: 53px;
	}
	.con-icon-title-blk {
		margin: 0px 50px 55px 0px;
	}
	.con-shopify-wr .con-right-wr, .con-open-cart .con-left-wr {
		width: 40%;
	}
	.con-open-cart .con-left-wr{
		left: 0;
	}
	.con-shopify-wr .con-right-wr, .con-open-cart .con-left-wr{
		right: 0;
	}
	.con-ecom-list-wr:before{
		right: -78px;
	}
	.header-contact-content {
		width: 70%;
	}
}/*1680*/
@media only screen and (max-width: 1679px) {
    /* Home Page */
    .center-wrapper {
	    width: 92%;
	}
	.project-widget-area {
	    width: 94%;
	    margin: 220px auto 0px;
	}
	.footer-content-block {
    	margin-left: 3.5%;
	}
	.development-blocks {
    	width: 78%;
	}
	.menu-blk , .offilne-menu-blk{
	    margin-left: 61px;
	}
	.our-work-blk {
	    margin-left: 46px;
	}
	h1 .large-head, .testimonial-head h3 {
	    font-size: 140px;
	    line-height: 151px;
	}
	h1 span {
	    font-size: 44px;
	    line-height: 55px;
	}
	.dynamic-txts li span {
	    font-size: 89px;
	    line-height: 108px;
	}
	.dynamic-txts {
    	height: 106px;
	}
	.footer-content-block h4 ,  .development-block h4 a{
	    font-size: 20px;
	    line-height: 24px;
	}
    .back-to-top {
        left: 0%;
    }
	.header-menu-block ul li {
		margin: 0px 0px 0px 70px;
	}
	.submenu li {
		margin: 0 0px 23px;
	}
    /* Life At Conative */
    .page-banner-wr {
        padding: 70px 0 100px;
    }
    .con-right-blk {
        margin: 24px 0px 20px;
    }
    .con-head-blk p {
        margin-top: 30px;
    }
    /*Contact Us*/
	.contact-page-form-content .form-checkbox-input {
    	vertical-align: top;
	}
	/*Our Team*/
	.con-team-content h2 {
	    font-size: 42px;
	    line-height: 47px;
	}
	/*Enquiry Form*/
	.enquiry-form-content {
    	width: 60%;
	}
	/* Website Single */
	.con-sidebar-wr ul li a {
		font-size: 18px;
		line-height: 21px;
	}
	/*Services pages*/
	.con-full-stack-wr .con-full-wr, .con-left-right-wr.con-php-wr {
    	margin-top: 74px;
	}
	/*eccommerce*/
	section.con-ecom-services-wr{
		overflow: hidden;
	}
	.con-eccom-wr .con-about-img {
    	width: 100%;
	}
	.con-eccom-wr .con-overlay-title {
	    width: 67%;
	}
	.con-ecom-list-wr:before {
		right: -128px;
		background-size: 80%;
	}
	.con-shopify-wr .con-right-wr, .con-open-cart .con-left-wr {
	    width: 49%;
	    right: -6%;
	}
	.con-open-cart .con-left-wr {
	    left: -8%;
	    width: 44%;
	}
	.con-big-com-wr .con-big-com-blk.con-head-blk.con-page-content {
	    width: 84%;
	    padding: 0px 36px 34px;
	}
	.header-bottom-wrapper .center-wrapper:before {
		width: 100%;
		left: 0;
	}
	.con-team-content-wr .white-ring-circle{display: none;}
	.con-inquiry-card {
		width: 28%;
	}
	.diwali-modal {
		max-height: 105vh;
	}
	.header-contact-content {
		width: 74%;
	}
	.logo-block {
		width: 23%;
	}.animate-character{
		font-size: 65px;
		line-height: 70px;
	}.animate-char-wr{
		bottom:55px ;
	  }.marquee-container h3 .btn {
		right: 3%;
	}.white-logo-img{
		width: 21%;
	}
}/* 1501 */
@media only screen and (max-width: 1500px){
    /* Home Page*/
	.header-contact-content {
	    margin: 22px 0px 35px;
	}

    .project-block h3 {
	    font-size: 28px;
	    line-height: 33px;
	}
	.project-block figcaption {
    	padding: 30px 30px 24px;
	}	
	.banner-content {
		width: 100%;
		margin-top: 20px;
	}
	.experience-list ul li {
    	padding: 0px 33.5px 0px 48.5px;
	}
	.development-blocks {
    	width: 86%;
	}
	.get-started-content {
		padding: 38px 50px 69px;
	}
	.get-started-content h3 span:first-child {
	    font-size: 114px;
	    line-height: 124px;
	}
	.common-heading span:nth-child(2) {
	    font-size: 54px;
	    line-height: 62px;
	}
	.development-block {
	    width: 31%;
	    padding: 84px 41px 51px 41px;
	}
	.testimonial-widget-area .bx-controls-direction {
	    bottom: 54px;
	    left: 75px;
	}
	.certification-slider-inner {
    	width: 95%;
	}
	.client-say-block {
    	width: 47%;
	}
	.certification-slider-con {
    	margin-top: 82px;
	}
	.lets-talk-blk {
    	width: 72%;
    	margin: -270px auto 0px;
	}
	.footer-content-block {
    	margin-left: 5.5%;
	}
	.footer-our-company {
    	width: 14%;
	}
	.common-round-btn {
	    padding: 20px 71px 20px 31px;
	}
	.digital-section-wr {
    	margin-top: 109px;
	}
	.certification-wr {
	    padding: 121px 0 230px 0;
	    background: url(../images/Awards_Footer_Bg_Image.png) no-repeat 100% 100%/ 47%;
	}
	footer {
    	padding-top: 185px;
	}
	.footer-top {
    	padding-top: 110px;
	}
	.banner-image-blk{
		top: 30px;
	}
    /* /Home */
    /*About Us*/
	.con-overlay-title h2 span:nth-child(2) {
	    font-size: 50px;
	    line-height: 61px;
	    padding: 0;
	}
	.con-overlay-title {
	    padding: 38px 40px;
    }	
    .con-about-img {
    	width: 63.5%;
	}
	.con-img-title-blk {
    	margin-bottom: 32px;
	}
	.con-page-content p {
    	margin-top: 19px;
	}
	.con-space-wr {
    	padding: 97px 0px 90px;
	}
	.con-vison-wr {
	    padding: 118px 0px 115px;
	}
	.con-vison-content h3 span:nth-child(2), .con-vison-content h3 {
	    font-size: 42px;
	    line-height: 75px;
	}
	.con-vison-content h3 span:first-child {
	    margin: 0px auto 22px;
	}
	.con-inquiry-wr {
    	margin: 96px 0px;
	}
	.con-inquiry-card {
	    width: 406px;
	}
	.con-head-blk {
	    padding-bottom: 75px;
	}
	.con-card-blk {
	    padding: 40px 25px;
	}
	.con-journey-wr {
    	padding: 79px 0px 31px;
	}
	.con-journey-wr {
    	padding: 79px 0px 31px;
	}
	.con-journey-head h3 span:nth-child(2), .con-skill-title h3 span:nth-child(2), .con-team-built-head h3 span:nth-child(2) {
	    font-size: 44px;
	    line-height: 51px;
	    padding: 0;
	}
	.con-skill-left {
	    width: 27%;
	    padding: 88px 0px 119px 28px;
	}
	/*Contact Us*/
	.conatct-form-detail-inner {
    	width: 95%;
    }
    .conatct-form-detail {
	    width: 40%;
	    padding: 132px 18px 275px 0px;
	}
	/*Our Team*/
	.con-team-contact-left .common-heading h3 span:nth-child(2), .con-team-contact-left .common-heading h3 span:nth-child(2) span {
		font-size: 38px;
		line-height: 50px;
		padding: 0;
	}
	.con-team-contact-left {
	    width: 47%;
	    position: absolute;
	    left: -621px;
	}
	.team-icon-title-wr .con-icon-title-blk {
	    padding: 41.8px 22px 10px;
	}
	.team-icon-title-wr .con-icon-title-blk {
	    padding: 28.8px 15px 9px;
	    min-height: 228px;
	}
	.team-icon-title-wr .con-icon-blk {
	    min-height: 54px;
	    margin-bottom: 22px;
	}
	section.con-team-built-wr {
    	padding-bottom: 136px;
	}
	/*testimonial*/	
	.con-space-wr {
		padding: 117px 0px 138px;
	}
	.testimonial-post-area{
		text-align: center;
	}
	/*career Single*/
	.career-business-development-wr {
    	padding: 113px 0 183px;
	}
	.career-business-development-inner {
    	width: 95%;
    }
    .career-form-heading {
    	width: 100%;
	}
	.career-file-con::after {
    	padding: 18px 19px;
	}
	/*mobile app development*/
	.con-mobile-app-wr .con-about-img {
    	width: 81.5%;
	}
	section.con-mobile-app-wr .con-overlay-title {
    	width: 61%;
    }
    /*Services Pages*/
    .con-full-wr.con-head-blk.con-jquery-wr {
	    margin: 80px auto 0px;
	}
	.con-full-wr .con-head-blk.con-page-content {
	    width: 71%;
	    padding: 0px 39px 1px;
	}
	.con-full-stack-wr .con-full-wr, .con-left-right-wr.con-php-wr {
    	margin-top: 74px;
	}
	.con-full-wr.con-head-blk.con-jquery-wr {
	    width: 94%;
	}
	.con-queries-content, .con-design-sol-content, .con-work-content {
	    width: 85%;
	}
	/*mobile app development*/
	section.con-flutter-app-wr.con-space-wr {
    	padding-top: 20px;
	}
	.con-flutter-app-wr .con-overlay-title {
	    margin-left: 5%;
	    width: 53%;
	}
	/*mobile*/
	.image-content-wr {
	    border-radius: 18px;
	    padding: 35px 36px;
	}
	/*animation*/
	span.con-logo-process {
	   
	}
	span.horizontal-line.horizontal-line-2 {
	    top: 122px;
	}
	span.horizontal-line.horizontal-line-3 {
    	bottom: 57px;
	}
	/*animation*/
	.con-anim-right-wr .con-page-content {
    	width: 100%;
	}
	span.con-logo-process {
	    padding: 17px 15px;
	    margin: 0px 0px 68px 20px;
	}
	span.horizontal-line.horizontal-line-3 {
    	bottom: 97px;
	}
	span.horizontal-line.horizontal-line-2 {
    	top: 149px;
	}
	span.vertical-line.vertical-line-2 {
    	height: 118px;
	}
	.con-motion-graphic-wr .con-anim-left-right-wr {
    	width: 93%;
	}
	.con-video-wr.con-space-wr .con-about-img{
		width: 70%;
	}
	.con-inner-gifs-wr {
	    width: 76%;
	    padding-left: 89px;
	}
	.con-inner-gifs-wr:before {
	    width: 329px;
	    height: 585px;
	    left: -269px;
	    background-size: 100%;
	}
	.gifs-list-wr .con-motion-list-wr {
		width: 100%;
		margin: -107px 0px 0px 0%;
	}
	.con-video-wr .con-overlay-title{
	    padding: 39px 107px 54px 45px;
	    width: 54%;
	    margin: -131px auto 0px 40%;
	}
	.con-technologies-wr {
		padding-bottom: 180px;
	}
	/* Menu */
	.header-menu-block {
		width: 70%;
		margin: 17px 0px 0px 5%;
	}	
	.digital-image-blk {
		width: 1300px;
	}
	.digital-slider-area {
		width: 38%;
	}.header-contact-content {
		width: 76%;
	}
	.logo-block {
		width: 21%;
	}.conatct-form-detail {
		width: 37%;
	}.contact-page-form-inner {
		width: 90%;
	}.contact-page-form-inner {
		width: 100%;
	}.contact-page-form-content .form-checkbox-input {
		display: inline-block;
		margin: 0px 0% 28px 0px;
		width: 33%;
	}.loader-event-img.twice {
		width: 390px;
	}
	.numbers div,.counter-aniv{
		width: 400px;
	}@keyframes countNumber {
		0% {
			margin-left:0px
		}
		100% {
			margin-left:-4000px
		}
	  }.banner-image-blk.aniversary figure:nth-child(1) {
		transform: translateY(30%);
	}.banner-image-blk.right.aniversary {
		width: 53%;
	}.aniversary-sparkle-collection-wr.top-right-sparkle {
		top: 8.5%;
	}.aniversary-sparkle-collection-wr.bottom-left-sparkle {
		bottom: -57%;
	}.aniversary-sparkle-collection-wr.ribbon {
		top: 42%;
	}.aniversary-sparkle-collection-wr.big-star {
		top: 18%;
	}.aniversary-sparkle-collection-wr.big-star.for-anim-top-right, .aniversary-sparkle-collection-wr.big-star.for-anim-top-left {
		top: 20%;
	}.aniversary-sparkle-collection-wr.exclaim {
		bottom: -30%;
		top: auto;
	}.marquee-container h3,.marquee-container h3 span {
		font-size: 20px;
	}.animate-character {
		font-size: 60px;
		line-height: 62px;
	}

}/*1440*/
@media only screen and (max-width: 1439px) {
	.form-checkbox label {
		font-size: 13px;
	}
    /* Life At Conative */
    .con-life-description-blk p {
        padding: 20px 0px 0;
    }
    .con-right-blk .contact-social-icon-list {
        margin-top: 20px;
    }
	/* Portfolio */
	.post-tabs-wr {
		width: 100%;
		margin: 90px auto 0px;
	}
	.post-grid-blk {
		width: 47%;
		background-size: 100%;
	}
	.post-grid-blk a img {
		width: 73%;
	}
	.grpahic-sub-category li {
		margin: 8px 0px;
	}
	/* industries we serve */
	.company-branding-wr .testimonial-head h3 {
		font-size: 228px;
		line-height: 288px;
	}
	.company-branding-blk {
		margin-top: 13px;
	}
	section.company-branding-wr.con-space-wr {
		padding-bottom: 10px;
	}
	.con-icon-title-blk {
		margin: 0px 50px 55px 0px;
		padding: 50px 0px;
	}
	 .con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 44px;
		line-height: 55px;
	}
	.con-industries-wr p{
		width: 100%;
	}
	.con-industries-wr .con-head-blk h4 {
		width: 100%;
	}
	.triangle:after, .triangle:before {
		width: 300px;
		height: 300px;
		background-size: 100%;
	}
	.con-industries-wr .con-head-blk h4 {
		font-size: 19px;
		line-height: 24px;
	}
	.con-technologies-wr .con-tect-tab {
		width: 98%;
		padding: 53px 42px;
	}
	.con-technologies-wr .design-sol-tabber-link li {
		width: 30%;
	}	
	.design-sol-tabber-link li a {
		padding: 35px 50px;
		font-size: 19px;
		line-height: 22px;
	}
	.inquiry-form-title h3 span:nth-child(2) {
		font-size: 38px;
		line-height: 47px;
		padding: 0;
	}
	.inquiry-form-title {
		padding-bottom: 53px;
	}
	.con-technologies-wr .con-head-blk h4 {
		margin-top: 25px;
		font-size: 21px;
		line-height: 28px;
	}	
	.con-technologies-wr {
		padding-bottom: 152px;
	}
	.banner-img-overlay .con-page-content{
		margin-bottom: 0;
	}
	.banner-img-overlay {
		top: 36%;
		width: 35%;
	}
	.mockup-banner-img {
		width: 48%;
		bottom: 0;
	}
	.project-widget-area {
		width: 94%;
		margin: 180px auto 0px;
	}
	.footer-bottom {
		width: 56%;
	}
	.team-icon-title-wr .con-title-blk h4 {
		font-size: 19px;
		line-height: 29px;
	}
	.team-icon-title-wr .con-icon-title-blk {
		padding: 28.8px 8px 9px;
		min-height: 228px;
	}
	/* Menu */
	.header-menu-block ul li {
		margin: 0px 0px 0px 74px;
	}
	.submenu li a > span.submenu-text-wr {
		width: 74%;
	}
	.header-menu-block ul li {
		margin: 0px 0px 0px 57px;
	}
	.header-logo-block {
		width: 17%;
	}
	.menu-close-icon {
		width: 6%;
	}
	.header-bottom-info-wrap a {
		width: 28%;
	}
	.header-bottom-info-wrap h3 {
		width: 70%;
	}
	.header-bottom-wrapper {
		padding-top: 46px;
	}
	.header-conative-title h3 {
		font-size: 190px;
		line-height: 239px;
	}
	.life-at-conative-slider {
		padding: 0px 50px;
	}
	.life-at-conative-content{
		width: 100%;
	}
	.con-mangneto-wr .con-overlay-title {
		width: 27%;
	}
	.con-react-list-wr.con-tab-list-wr ul li:nth-child(3n+1) {
		margin-left: 26px;
	}
	.submenu li a > span.submenu-text-wr {
		width: 77%;
	}
	.common-round-btn {
		padding: 20px 49px 20px 21px;
		margin: 0px 0px 10px 8px;
	}
	.common-round-btn::before{
		right: 19px;
	}
	.build-career-popup-wrapper {
		min-height: auto;
	}
	.enquiry-form-content, .build-career-popup-wrapper {
		width: 52.8%;
		margin: 0 auto;
		padding: 30px 0 53px;
	}
	/* 10/04/2023 */
	.header-contact-information .contact-details-block {
		margin-left: 65px;
	}
	.our-work-blk {
		margin-left: 28px;
	}.contact-blk a {
		margin-left: 26px;
	}
	.menu-blk , .offilne-menu-blk{
		margin-left: 22px;
	}.animate-character {
		font-size: 53px;
		line-height: 54px;
	}
	.loader-dot::before {
		transform: translate(120px,-105px);
	}.loader-wave-container{
		width: 70%;
	}
	.counter-sub {
		font-size: 41px;
		line-height: 49px;
	}.numbers div, .counter-aniv {
		width: 385px;
	}@keyframes countNumber {
		0% {
			margin-left:0px
		}
		100% {
			margin-left:-3850px
		}
	  }
	  .loader-counter-container{
		width: 35%;
	  }.loader-outline-container{
		width: 44%;
	  }.loader-sparkle-container{
		width: 39%;
	  }
}
/*1366*/
@media only screen and (max-width: 1365px) {
    /* Home Page */
	
    .header-contact-content {
    	width: 70%;
	}
	.circle {
		width: 326px;
		height: 326px;
	}
	.circle h1 {
		font-size: 53px;
		line-height: 76px;
		margin: 91px 0px;
	}
	.our-work-blk {
    	margin-left: 23px;
	}
	.menu-blk , .offilne-menu-blk{
    	margin-left: 39px;
	}
	.contact-blk a {
	    margin-left: 19px;
	}
	h1 .large-head, .testimonial-head h3 {
    	font-size: 119px;
    	line-height: 144px;
	}
	.dynamic-txts li span {
	    font-size: 81px;
	    line-height: 113px;
	}
	.dynamic-txts li span::after {
    	height: 90%;
	}
	.dynamic-txts {
    	height: 93px;
	}
	p {
	    font-size: 20px;
	    line-height: 39px;
	}
	.banner-image-blk {
		width: 51%;
		right: -13px;	
	}
	.banner-wr {
    	padding: 38px 0px 35px;
	}
	.experience-list ul li {
    	padding: 0px 20px 0px 35px;
    	font-size: 22px;
    	line-height: 28px;
	}
	.get-started-content h3 span:first-child {
	    font-size: 105px;
	    line-height: 115px;
	}
	.get-started-content h4, .get-started-content ul.dynamic-txts {
	    font-size: 45px;
	    line-height: 63px;
	}
	.get-started-content ul.dynamic-txts {
	    height: 51px;
	    line-height: 57px;
	    margin-left: -128px;
	    top: -8px;
	}
	.get-started-slider-area .bx-pager {
    	bottom: 35px;
	}
	.get-started-blk a {
	    margin-top: 23px;
	    padding: 17px 34px;
	}
	.get-started-content {
		padding: 40px 48px 64px;
	}
	.get-started-slider-area .bx-controls-direction a {
	    width: 48px;
	    height: 86px;
	}
	.common-heading span:nth-child(2) {
	    font-size: 52px;
	    line-height: 62px;
	    padding: 0px 92px;
	}
	.development-block h4 {
	    font-size: 22px;
	    line-height: 30px;
	}
	.certification-heading.left span:nth-child(2) {
    	padding: 0;
	}
	.development-block {
	    padding: 84px 27px 51px 24px;
	}
	.our-expertise-wr {
    	padding: 120px 0 127px 0;
	}
	.industries-wr {
    	padding: 112px 0;
	}
	.our-project-heading.left h3 span:nth-child(2) {
	    font-size: 51px;
	    line-height: 62px;
	    padding: 0;
	}	
	.project-block h3 {
	    font-size: 25px;
	    line-height: 29px;
	}
	.project-block figcaption {
    	padding: 25px 21px 20px;
	}
	.header-contact-content {
    	margin: 12px 0px 14px;
	}
	.lets-talk-blk {
		width: 87%;
	}
	.project-widget-area {
		width: 94%;
		margin: 151px auto 0px;
	}
    /* Life At Conative */
    .con-life-wr {
        padding: 50px 0px 150px;
    }
    .con-life-description-blk p {
        font-size: 17px;
        line-height: 38px;
    }
    .con-head-blk p, .cits-inner-head-content p {
        font-size: 20px;
        line-height: 40px;
    }
    .con-alternate-blk {
        margin-top: 50px;
    }
    .con-left-blk {
        width: 42%;
    }
    .con-right-blk {
        margin: 0px 0px 20px;
    }
    /* Career */
    .common-heading span:nth-child(2) {
        font-size: 62px;
        line-height: 64px;
    }
    .perks-benefit-box {
        padding: 64px 30px 38px 30px;
        width: 23.8%;
        margin: 0 0 42px 1.6%;
    }
    .perks-benefit-all-boxes {
        margin-top: 60px;
    }
    .cits-inner-head-content-wr, .cits-inner-signle-wr {
        padding-top: 70px;
    }
    .build-career-blocks-inner {
        width: 90%;
    }
    .build-career-all-blocks {
        padding-top: 80px;
    }
    .build-career-para p {
        line-height: 26px;
    }
    /*About Us*/
	.con-vison-content {
    	width: 88%;
	}
	.con-vison-content h3 span:nth-child(2), .con-vison-content h3 {
		font-size: 39px;
		line-height: 65px;
	}
	.con-page-content {
    	width: 91%;
	}
	.con-vison-wr {
    	padding: 87px 0px 78px;
	}
	.con-card-blk {
    	padding: 35px 23px;
	}
	.con-card-title h3 {
	    font-size: 28px;
	    line-height: 30px;
	    margin-bottom: 11px;
	}
	.con-journey-slide {
    	min-height: 475px;
	}
	.con-overlay-title h2 span:nth-child(2) {
	    font-size: 47px;
	    line-height: 57px;
	}
	.con-contact-card.con-card {
    	width: 80%;
	}
	.con-contact-card h3 {
		font-size: 42px;
		line-height: 45px;
	}
	.con-choose-wr {
    	padding-top: 73px;
	}
	/*Our Team*/
	.con-team-contact-left .common-heading h3 span:nth-child(2), .con-team-contact-left .common-heading h3 span:nth-child(2) span {
	    font-size: 34px;
	    line-height: 45px;
	    padding: 0;
	}
	.con-team-contact-left {
	    width: 45%;
	    position: absolute;
	    left: -618px;
	}
	.con-team-contact-right figure:before {
	    top: -94px;
	    left: -119px;
	    width: 231px;
	    height: 306px;
	    background-size: 100%;
	}
	.con-team-upload-wr {
    	margin-bottom: 43px;
	}
	.con-team-contact-left .common-heading {
	    margin-bottom: 29px;
	    padding-right: 6px;
	}
	.con-team-content-wr {
    	padding: 64px 0px 56px;
	}
	.con-team-inner {
    	padding-left: 30px;
	}
	/*enquiry Form*/
	.enquiry-form-content {
    	width: 64%;
	}
	/*Career Detail*/
	.career-apply-now-form {
	    width: 43%;
	    padding: 49px 33px;
	}
	.career-business-development-inner {
    	width: 100%;
	}
	.business-development-heading {
    	margin-top: 24px;
	}
	/* Portfolio */
	.post-grid-blk a {
		height: 210px;
	}
	/*Services*/
	.con-full-wr.con-head-blk.con-jquery-wr {
	    width: 100%;
	    margin: 64px auto 0px;
	}
	.page-banner-heading, .con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
	    font-size: 42px;
	    line-height: 58px;
	}
	.con-seo-content, .con-six-box-blk, .con-left-right-wr, .con-web-develop-design-wr .con-anim-left-right-wr, .con-ecom-services-inner-wr {
    	width: 100%;
	}
	.con-larvel-wr {
	    padding: 39px 54px 49px 56px;
	    margin: 124px auto 97px;
	}
	.con-react-wr  .con-about-img {
    	width: 94.5%;
	}
	.con-react-wr .con-page-content {
    	width: 93%;
	}
	.con-react-wr .con-overlay-title {
	    width: 57%;
	}
	.con-full-stack-wr .con-left-right-wr {
    	width: 94%;
	}
	.con-full-stack-wr .con-full-wr{
    	margin-top: 47px;
	}
	.con-full-image-blk {
	    padding: 19px 16px;
	    width: 93%;
	}
	.con-html-css-wr {
    	width: 94%;
	}
	.con-left-right-wr.con-javascript-wr {
    	margin-top: 110px;
	}
	.con-backend-wr .con-right-wr {
    	padding: 70px 0px;
	}
	.con-left-right-wr.con-node-wr {
	    margin-top: 113px;
	}
	.con-web-app-wr .con-about-img {
    	width: 86%;
	}
	section.con-web-app-wr .con-overlay-title {
	    width: 59%;
	    padding: 33px 31px 42px 37px;
	    margin-top: -60px;
	}
	.con-full-wr.con-php-list-wr {
    	width: 100%;
	}
	.con-full-wr.con-php-list-wr ul li {
	    padding: 24px 17px;
	    font-size: 17px;
	    line-height: 26px;
	}
	.con-code-wr.con-full-wr .con-page-content {
		width: 100%;
	}
	.con-page-content .con-blockquote-blk p {
	    font-size: 30px;
	    line-height: 54px;
	}
	.con-react-features {
	    width: 100%;
	    margin: 62px auto 0px;
	    padding: 41px 35px 42px;
	}
	.con-full-wr .con-head-blk.con-page-content {
	    padding: 0px 23px 1px;
	}
	.con-head-blk {
    	padding-bottom: 48px;
	}
	.con-code-wr .con-about-img {
    	width: 85%;
	}
	.con-code-wr.con-full-wr .con-overlay-title {
    	width: 34%;
	}
	.con-services-content {
    	padding-top: 50px;
	}
	.other-services-list li {
    	margin-left: 27px;
	}
	.con-react-list-wr.con-tab-list-wr ul li:nth-child(3n+1){
		    margin-left: 26px;
	}
	/*mobile app development*/
	.mobile-header-left-right-wr, .mobile-bottom-header-wr {
	    width: 85%;
	}
	.con-flutter-app-wr .con-about-img {
    	width: 97%;
	}
	.con-digital-services-wr .con-anim-left-right-wr, .con-cross-outer-wr {
	    width: 90%;
	}
	section.con-mobile-app-wr .con-overlay-title {
	    width: 69%;
	    padding: 30px 30px 43px 30px;
	}
	.react-lib-wr.image-content-wr {
	    padding: 38px 31px 41.5px 21px;
	    width: 95%;
	}
	/*eccommerce*/
	.services-page-content-blk {
    	width: 96%;
	}
	.con-ecom-list-wr {
	    padding: 47px 214px 46px 34px;
	}
	.con-ecom-list-wr:before {
	    width: 353px;
	    height: 311px;
	    right: -81px;
	    background-size: 80%;
	}
	.con-shopify-wr .con-right-wr, .con-open-cart .con-left-wr {
	    width: 44%;
	    right: -2%;
	}
	.con-open-cart .con-left-wr {
	    left: -3%;
	    width: 41%;
	}
	.con-full-wr.con-big-com-wr {
    	margin-top: 64px;
	}
	/*digital solutions*/
	.con-digital-services-wr .con-anim-left-right-wr{
		width: 100%;
	}
	.con-tab-list-wr li {
	    padding: 10px 0px 10px 50px;
	    font-size: 18px;
	    line-height: 19px;
	}
	.social-media-marketing-content, .con-flutter-bottom-app-wr .con-tab-list-wr {
	    width: 92%;
	    margin: 37px auto 0px;
	    padding: 50px 48px 51px 39px;
	}
	.social-media-marketing-content ul li span.list-title {
    	width: 78%;
	}
	.con-video-wr .con-overlay-title, .con-content-manage-wr .con-overlay-title {
		padding: 37px 70px 44px 46px;
		width: 50%;
		margin: -122px auto 0px 40%;
	}
	.con-work-service-wr {
	    padding: 58px 0px 213px;
	}
	.con-queries-content, .con-design-sol-content, .con-work-content {
    	width: 95%;
	}
	/* designing*/
	.con-illustration-wr {
	    width: 96%;
	}
	section.con-ui-design-wr.con-space-wr .con-about-img {
    	width: 87.5%;
	}
	.con-customers-wr.con-tab-list-wr, .con-ui-design-wr .con-page-content, .product-braning-inner-wr .con-page-content {
    	width: 90%;
    }
    .con-ui-design-wr .con-overlay-title {
	    width: 61%;
	}
	.con-logo-solution-wr, .con-full-width-wr.con-ue-wr {
    	width: 91%;
    }
    .con-left-right-wr.con-ux-wr .con-right-wr {
	    width: 59%;
	    padding-right: 0;
	}
	section.con-ui-design-wr.con-space-wr{
		padding: 100px 0px 90px;
	}
	.con-graphic-design-inner-wr {
	    width: 96%;
	}
	.con-graphic-design-inner-wr .con-blockquote-blk p {
	    font-size: 32px;
	    line-height: 52px;
	}
	.con-graphic-design-inner-wr .con-blockquote-blk {
    	margin: 44px 0px 30px;
	}
	section.con-logo-wr {
    	padding: 89px 0px;
	}
	.con-logo-wr .con-full-width-wr {
	    width: 94%;
	    margin: 83px auto 0px;
	}
	.con-illustration-wr:before {
	    width: 273px;
	    height: 450px;
	    top: auto;
	    left: 145px;
	    bottom: -147px;
	    background-size: 75%;
	}
	.circle-fill-btn {
	    font-size: 15px;
	    line-height: 26px;
	}
	/*animations*/
	.con-animations-wr .con-blockquote-blk p {
	    font-size: 30px;
	    line-height: 50px;
	}
	.con-blockquote-blk blockquote, .con-blocquote-wr ul {
		padding-left: 40px;
		border-left: 5px solid #f84525;
	}
	.con-animations-wr .con-blockquote-blk {
    	width: 90%;
    	margin-bottom: 20px;
	}
	.con-motion-graphic-wr .con-anim-left-right-wr {
	    padding-top: 47px;
	    width: 100%;
	}

	.explainer-video-wr .con-anim-left-right-wr {
    	width: 100%;
    }
    .con-video-wr.mobile-app-wr {
    	padding-top: 142px;
	}
	.con-video-wr.mobile-app-wr .con-about-img {
    	width: 79.5%;
	}
	.con-logo-process-blk{width: 100%;}
	span.con-logo-process {
	    padding: 17px 12px;
	    margin: 0px 0px 68px 17px;
	    font-size: 15px;
	    line-height: 20px;
	}
	/* industries we serve */
	.con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 39px;
		line-height: 51px;
	}
	.con-icon-title-blk {
		margin: 0px 37px 43px 0px;
		padding: 31px 0px;
	}
	.con-industries-wr .con-head-blk {
		padding-bottom: 18px;
		margin-top: 18px;
	}
	/* menu */
	.header-contact-information .contact-social-icon-list li {
		margin-left: 6px;
	}

	.digital-image-blk {
		width: 1000px;
	}
	.digital-slider-area {
		top: 20px;
	}
	.other-services-list li {
		margin-left: 67px;
	}
	.con-head-blk p, .cits-inner-head-content p {
		font-size: 18px;
		line-height: 42px;
	}
	.con-flutter-bottom-app-wr .con-right-wr {
		bottom: 24px;
		width: 29%;
	}
	.diwali-modal {
		max-height: 109vh;
	}
	.header-contact-content {
		width: 75%;
	}
	.menu-blk , .offilne-menu-blk{
		margin-left: 25px;
	}
	.our-work-blk {
		margin-left: 19px;
	}
	.aniversary-img-container{
		width: 48%;
		margin: 0 auto;
	}
	.counter-sub {
		display: block;
		font-size: 51px;
		line-height: 49px;
	}
	.loader-counter-container .counter-suffix {
		font-size: 63px;
	}.numbers div{
		font-size: 120px;
		line-height: 200px;
	}.loader-sparkle-container{
		width: 90%;
	}.loader-counter-container{
		top: 48%;
	}
	.banner-image-blk.aniversary figure:nth-child(1) {
		transform: translateY(49%);
	}.aniversary-sparkle-collection-wr.top-right-sparkle {
		top: 12.5%;
	}.aniversary-sparkle-collection-wr.bottom-left-sparkle {
		bottom: -62%;
	}.aniversary-sparkle-collection-wr.ribbon {
		top: 48%;
	}.aniversary-sparkle-collection-wr.big-star {
		top: 24%;
	}.aniversary-sparkle-collection-wr.big-star.for-anim-top-right, .aniversary-sparkle-collection-wr.big-star.for-anim-top-left {
		top: 35%;
	}.aniversary-sparkle-collection-wr.exclaim {
		top: auto;
		bottom: -38%;
	}
	.animate-char-wr {
		bottom: 45px;
		left: 54px;
	}	.loader-outline-container{
		width: 100%;
	  }.white-logo-img {
			width: 26%;
	}
}
/*1279*/
@media only screen and (max-width: 1280px){
	/* home footer */
	.footer-content-block.footer-industries-serve {
		width: 18%;
	}

	.testimonial-head {
		top: 123px;
		left: 54px;
	}
	/* indusrties */
	.company-branding-wr .testimonial-head h3 {
		font-size: 194px;
		line-height: 280px;
	}
	.design-sol-tabber-link li a {
		font-size: 18px;
		line-height: 20px;
		padding: 23px 11px;
	}
	/* home */
	.testimonial-review-blk {
		width: 46%;
	}
	section{overflow: hidden;}
	.con-flutter-bottom-app-wr .con-right-wr{
		right: 0;
	}
	.enquiry-form-content {
		width: 69%;
	}
	.career-popup-form {
		height: 350px;
		margin-top: 23px;
		overflow-y: scroll;
	}
	.header-contact-information .contact-details-block {
		margin-left: 3%;
	}
	.overflow_content {
		height: 336px;
	}
	.header-bottom-wrapper .center-wrapper{
		padding-bottom: 45px;
	}
	.header-menu-block ul li {
		margin: 0px 0px 0px 45px;
	}
	.submenu li a > span.submenu-text-wr {
		width: 70%;
	}
	.header-contact-information .header-contact-left {
		width: 54%;
	}
	.header-contact-information .social-icon-form-left {
		width: 45%;
	}
	.contact-blk a {
		margin-left: 2px;
	}
	.our-work-blk {
		margin-left: 8px;
	}
	.header-contact-content {
		width: 80%;
	}
	.logo-block {
		width: 20%;
	}
	.con-testimonial-thumbnail figcaption {
		position: relative;
		bottom: -40px;
	}@keyframes scrollAnimation {
		0% {
		  left: 0px;
		  transform: translateX(0);
		}
		50% {
		  transform: translateX(121vw); 
		}51%{
		  transform: translateX(121vw) scaleX(-1); 
		}
		100% {
		  left: -350px;
		  transform: translateX(0)  scaleX(-1); 
		}
	  }	@keyframes scrollAnimation {
		0% {
		  left: -350px;
		  transform: translateX(0);
		}
		50% {
		  transform: translateX(130vw); 
		}51%{
		  transform: translateX(130vw) scaleX(-1); 
		}
		100% {
		  left: -350px;
		  transform: translateX(0)  scaleX(-1); 
		}
	  }#loader-content-wr h1 {
		font-size: 75px;
	  }.animate-character {
		font-size: 50px;
		line-height: 52px;
	}.marquee-container h3, .marquee-container h3 span {
		font-size: 18px;
	}.marquee-wr{
		padding: 6px 0;
	}
	.loader-dot::before {
		transform: translate(123px,-105px);
	}.marquee-container .aniv-btn-inner {
		font-size: 14px;
		padding: 8px 37px 8px 14px;
	}
	
}
/*1200*/
@media only screen and (max-width: 1199px) {
	@keyframes scrollAnimation {
		0% {
		  left: -350px;
		  transform: translateX(0);
		}
		50% {
		  transform: translateX(130vw); 
		}51%{
		  transform: translateX(130vw) scaleX(-1); 
		}
		100% {
		  left: -350px;
		  transform: translateX(0)  scaleX(-1); 
		}
	  }
	/* diwali */
	.circle {
		width: 350px;
		height: 380px;
	}
	.circle h1 {
		margin: 121px 0px 151px;
		font-size: 58px;
		line-height: 74px;
	}
    /* Home */
	.project-widget-area {
		width: 100%;
	}
    .contact-blk a {
    	margin-left: 3px;
	}
	.our-work-blk {
    	margin-left: 11px;
	}
	.menu-blk , .offilne-menu-blk{
    	margin-left: 22px;
	}
	.common-round-btn {
		padding: 18px 42px 18px 24px;
		margin-left: 8px;
		font-size: 14px;
		line-height: 20px;
	}
	.features-description-blk {
		width: 57%;
	}
	.certification-heading.left {
		width: 42%;
	}
	.common-round-btn::before {
    	right: 17px;
	}
	p {
	    font-size: 19px;
	    line-height: 37px;
	}
	.experience-list ul li {
	    padding: 0px 13px 0px 19px;
	    font-size: 21px;
	    line-height: 26px;
	}
	.get-started-content h3 span:first-child {
	    font-size: 84px;
	    line-height: 108px;
	}
	.testimonial-review-blk p, .client-say-content p {
	    font-size: 22px;
	    line-height: 41px;
	}
	.testimonial-review-blk {
    	width: 47%;
	}
	.common-heading span:nth-child(2) {
	    font-size: 48px;
	    line-height: 52px;
	    padding: 0px 60px;
	}
	.development-block {
    	padding: 55px 18px 51px 18px;
	}
	.all-development-blocks {
    	margin-top: 67px;
	}
	.our-expertise-wr {
    	padding: 107px 0 94px 0;
	}
	.industries-box-heading {
    	margin-top: 19px;
	}
	.our-project-heading.left h3 span:nth-child(2) {
	    font-size: 47px;
	    line-height: 58px;
	}
	.our-project-wr {
    	padding: 109px 0 54px;
	}
	.testimonial-area {
    	margin-top: 114px;
    }
    .quote-icon-img {
    	margin-right: 17px;
	}
	.check-all-testimonial-btn {
	    margin-top: 6px;
	}
	.certification-slider-inner {
    	width: 100%;
	}
	.certification-slider-content-inner {
    	padding: 34px 12px 40px;
    }
    .certification-wr {
	    padding: 96px 0 149px 0;
	    background: url(../images/Awards_Footer_Bg_Image.png) no-repeat 100% 100%/ 51%;
	}
	.lets-talk-blk {
	    width: 97%;
	    padding: 49px 42px 56px 50px;
	}
	.footer-logo {
	    width: 100%;
	    margin-bottom: 55px;
	}
	.footer-content-block {
    	margin-left: 3.5%;
	}
	.footer-our-services {
    	width: 25%;
	}
	.footer-our-company {
	    width: 16%;
	    margin-left: 0;
	}
	.footer-bottom {
	    padding: 32px 0;
	    width: 73%;
	}
	.footer-top {
    	padding-top: 78px;
	}
	.footer-content-block.footer-industries-serve {
		width: 24%;
	}
	.footer-content-block h4 {
		font-size: 19px;
		line-height: 23px;
	}
	.development-block {
		width: 33%;
	}
	.get-started-content {
		padding: 19px 48px 18px;
	}
    /* Life At Conative */
    .banner-content {
        width: 100%;
        margin-top: 0;
    }
    .con-alternate-blk:nth-child(odd) .con-right-blk, .con-alternate-blk:nth-child(even) .con-right-blk {
        float: unset;
        width: 98%;
    }
    .con-alternate-blk:nth-child(odd) .con-left-blk {
        margin-right: 30px;
    }
    .con-alternate-blk:nth-child(even) .con-left-blk {
        margin-left: 30px;
    }
    /* Career */
    .build-career-img {
        margin-right: 1.5%;
    }
    .build-career-text {
        width: 83.5%;
        margin-top: 0;
    }
    .page-medium-heading h3 {
        font-size: 19px;
        line-height: 25px;
    }
    .build-career-wr {
        padding: 95px 0 80px;
    }
    .career-page-heading h3 {
        font-size: 42px;
        line-height: 52px;
    }
    .perks-benefit-wr {
        padding: 12px 0 32px;
    }
    .common-heading span:nth-child(2) {
		font-size: 48px;
	}
    /*About Us*/
	.con-about-img {
    	width: 92%;
	}
	.con-about-wr .con-overlay-title {
		width: 63%;
		padding: 29px 45px;
	}
	.con-about-img:before, .con-image-blk:before {
	    left: -61px;
	    bottom: -60px;
	}
	.con-img-title-blk {
    	margin-bottom: 19px;
	}
	.con-vison-content h3 span:nth-child(2), .con-vison-content h3 {
		font-size: 30px;
		line-height: 51px;
	}
	.con-vison-content {
    	width: 100%;
	}
	.con-contact-card.con-card {
    	width: 88%;
	}
	.con-card-blk {
	    padding: 35px 23px;
	    float: none;
		margin: 15px 0px 0px 2%;
	    display: inline-block;
	    vertical-align: top;
	    width: 48%;
	}
	.con-cards-wr {
    	text-align: center;
	}
	.con-journey-wr {
    	padding: 67px 0px 22px;
	}
	.con-skill-left {
		width: 27%;
		padding: 72px 0px 92px 0px;
	}
	.con-skill-blk {
	    width: 21.5%;
	    min-height: 129px;
	    margin: 0px 0px 30px 17px;
	}
	.con-skills-wr {
    	padding-bottom: 162px;
	}
	.con-slide-caption {
	    padding: 21px 17px;
	}
	.con-journey-slider-wr {
    	padding: 53px 0px 97px;
	}
	.slide-title h3, .slide-title h3 span {
	    font-size: 27px;
	    line-height: 30px;
	}
	.slide-title h3 {
    	padding-bottom: 7px;
	}
	/*Contact Us*/
	.contact-page-form-content .form-checkbox-input {
	    margin: 0px 1% 28px 0px;
	    width: 48%;
	}
	.contact-page-form-content .enquiry-form-services-looking-for {
    	margin-top: 15px;
	}
	.contact-page-form-content .form-field input, .form-field textarea {
	    padding: 12px 0 13px;
	    margin-bottom: 13px;
	    line-height: 20px;
	}
	.conatct-form-detail {
	    padding: 100px 18px 200px 0px;
	}	
	.location-bubble {
    	width: 298px;
    }
    .map-dots li:first-child {
	    top: 46%;
	}	
	.contact-social-icon-list li a, .linkdin-btn {
	    width: 40px;
	    height: 40px;
	}
	/*Our team*/
	.con-team-content h2 {
	    font-size: 35px;
	    line-height: 41px;
	}
	.con-team-content.con-overlay-title {
    	padding: 34px 31px;
	}
	.team-icon-title-wr .con-icon-title-blk {
    	width: 25%;
    }
    .team-icon-title-wr{text-align: center;}
    .team-icon-title-wr .con-icon-title-blk:nth-last-child(-n + 5) {
    	border-bottom: 1px solid rgba(0,0,0,0.1);
	}
	.team-icon-title-wr .con-icon-title-blk:nth-last-child(-n + 2){
		border-bottom: 0;
	}
	.team-icon-title-wr .con-icon-title-blk:nth-child(5n) {
    	border-right: 1px solid rgba(0,0,0,0.1);
	}
	.team-icon-title-wr .con-icon-title-blk:nth-last-child(1), .team-icon-title-wr .con-icon-title-blk:nth-child(4n){border-right: 0;}
	.con-team-contact-left {
	    width: 100%;
	    position: unset;
	    padding: 0px 3%;
	    max-width: 100%;
	}
	.con-team-contact-right {
		width: 76%;
		margin-top: 130px;
	}
	.con-team-contact-wr {
    	padding: 12px 0px 163px;
	}
	.con-team-contact-left .con-team-contact-wr {
		padding: 0px 0px 30px;
	}
	.team-icon-title-wr .con-title-blk h4 {
	    font-size: 20px;
	    line-height: 29px;
	}
	/*Testimonials*/
	
	.con-testimonial-wr .con-head-blk h2 {
    	margin-bottom: 19px;
	}
	.con-head-blk p, .cits-inner-head-content p {
		font-size: 18px;
		line-height: 36px;
	}
	.con-space-wr {
    	padding: 82px 0px 87px;
	}
	.con-testimonial-thumbnail {
	    height: 100%;
	}	
	.con-testimonial-thumbnail figcaption{
		position: unset;
	}
	/*enquiry Form*/
	.enquiry-form-content {
    	width: 78%;
	}
	/*Career Deatils*/
	.career-business-development-blk {
    	width: 100%;
    	float: unset;
	}
	.career-apply-now-form {
	    width: 76%;
	    padding: 39px 31px;
	    margin: 70px auto 0px;
	    float: unset;
	}
	/* 404 */
	section.error404-content .intro-text {
		width: 82%;
	}
	.error404-content figure {
		width: 33%;
		margin: 0px auto 45px;
	}
	.error-page-wr {
		padding: 109px 0px 185px;
	}
	/* website single */
	.con-sidebar-wr {
		width: 90%;
		margin: 0px auto;
		padding: 15px 19px;
	}
	.page-banner-heading, .con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
		font-size: 35px;
		line-height: 46px;
	}
	div#listTab {
		margin-bottom: 100px;
	}
	/*services pages*/
	.page-banner-heading, .con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
		font-size: 37px;
		line-height: 58px;
	}
	section.con-web-app-wr .con-overlay-title {
	    width: 64%;
	}
	.con-blockquote-blk blockquote, .con-blocquote-wr ul {
    	padding-left: 34px;
	}
	.con-page-content .con-blockquote-blk {
	    margin: 33px 0px;
	    width: 100%;
	}
	.con-head-blk {
    	padding-bottom: 39px;
	}
	.con-queries-content, .con-design-sol-content, .con-work-content {
	    width: 100%;
	}
	.other-services-list li {
		margin: 0px 0px 45px 16px;
	}
	.circle-fill-btn:hover img {
		transform: translateX(37px);
	}
	.circle-fill-btn {
	    font-size: 15px;
	    line-height: 26px;
	}
	.con-work-service-wr {
    	padding: 62px 0px 177px;
	}
	.con-full-wr.con-head-blk.con-jquery-wr {
		margin: 43px auto 0px;
	}
	.con-code-wr .con-about-img {
    	width: 100%;
	}
	.con-code-wr.con-full-wr .con-overlay-title {
    	width: 38%;
	}
	.con-tab-list-wr li {
	    padding: 10px 0px 10px 47px;
	    font-size: 18px;
	    line-height: 21px;
	    margin-bottom: 18px;
	}
	.con-react-head-wr h4 {
	    font-size: 21px;
	    line-height: 39px;
	}
	.con-services-content {
    	padding-top: 30px;
	}
	.con-why-code-wr .con-left-wr{
		width: 43%;
	}
	.con-page-content .con-blockquote-blk p {
		font-size: 27px;
		line-height: 44px;
	}
	.con-larvel-wr {
		margin: 81px auto 97px;
	}
	.con-full-wr.con-php-list-wr ul li {
	    padding: 23px 10px;
	    font-size: 16px;
	    line-height: 24px;
	    text-align: center;
	}
	/*mobile app development*/
	.mobile-header-left-right-wr, .mobile-bottom-header-wr {
	    width: 92%;
	}
	.con-mobile-app-wr .con-about-img {
    	width: 96.5%;
	}
	.react-lib-wr.image-content-wr {
	    width: 100%;
	    margin: 54px auto 0px;
	}
	/*Eccomerce*/
	.con-eccom-wr .con-overlay-title {
	    width: 70%;
	    margin: -100px auto 0px 25%;
	}
	.services-page-content-blk .con-img-title-blk {
    	margin-bottom: 70px;
	}
	section.con-ecom-services-wr {
    	padding-top: 83px;
	}
	.con-ecom-list-wr ul li {
    	width: 48%;
	}
	.con-woocom-wr {
    	margin-top: 80px;
	}
	.con-mangneto-wr {
    	margin-top: 80px;
	}
	/*digital Solutions*/
	.image-content-wr {
	    border-radius: 11px;
	    padding: 26px 23px;
	    margin-top: -3px;
	}
	.icon-content-blk {
	    width: 28.33%;
	    margin-left: 6%;
	}
	.services-page-content-blk .con-page-content{
    	width: 100%;
	}
	.social-media-marketing-content, .con-flutter-bottom-app-wr .con-tab-list-wr {
    	width: 100%;	
    }
    .social-media-marketing-content ul li {
	    width: 31%;
	    font-size: 17px;
	    line-height: 26px;
	    font-weight: 600;
	}
	.con-content-manage-wr .con-about-img {
    	width: 94.5%;
	}
	section.con-content-manage-wr {
	    overflow: hidden;
	}
	.con-content-manage-wr .con-overlay-title {
    	width: 59%;
	}
	.con-content-manage-wr .con-page-content {
    	width: 95%;
	}
	section.con-digital-services-wr .con-right-wr.right {
    	padding-right: 0;
	}
	/*designing*/
	.con-ui-design-wr .con-overlay-title {
    	width: 64%;
	}
	.con-customers-wr.con-tab-list-wr h4 {
		font-size: 28px;
		line-height: 29px;	
	}
	.con-customers-wr.con-tab-list-wr{
    	padding: 49.9px 27px 37px 23px;
    }
    .con-logo-solution-wr, .con-full-width-wr.con-ue-wr {
	    width: 76%;
	    margin: 58px auto 0px;
	}
	/*Services*/
	.services-page-content-blk .con-about-img, .services-page-content-blk .con-head-blk {
		width: 100%;
	}
	.services-page-content-blk .con-img-title-blk {
    	margin-bottom: 47px;
	}
	.services-page-content-blk {
	    width: 100%;
	}
	.con-animations-wr {
    	padding: 92px 0px 84px;
    }
    .con-animations-wr .white-ring-circle {
    	width: 611px;
    	height: 611px;
    }
    .con-anim-left-wr , .con-web-develop-design-wr .con-anim-left-right-wr .con-anim-left-wr{
		width: 78%;
		float: unset;
    	margin: 32px auto 0px;
	}
	.con-digital-services-wr .con-anim-left-wr {
		width: 100%;
	}
	.con-digital-services-wr .con-anim-right-wr {
		width: 100%;
		margin-top: 25px;
	}
	section.con-digital-services-wr {
		padding: 72px 0px 79px;
	}
	.con-node-wr .con-left-wr:before {
		width: 125px;
		height: 108px;
		top: -79px;
		left: 0;
	}
	.con-web-develop-design-wr .con-anim-left-right-wr .con-anim-left-wr{width: 100%;margin-bottom: 40px;}
	.con-anim-right-wr {
	    width: 100%;
	    float: unset;
	}
	.con-web-develop-design-wr .con-anim-left-right-wr .con-anim-right-wr {
		width: 100%;
	}
	/*animations*/
	.process-line-blk{
    	display: none;
	}
	.con-logo-process-wr .con-page-content {
    	padding: 36px 61px 36px 53px;
    }
    .con-logo-solution-wr{
    	width: 96%;
	}
	.con-motion-graphic-wr {
    	padding-top: 79px;
    }
    .con-anim-left-right-wr {
    	padding-top: 57px;
	}
	.con-motion-graphic-wr .con-anim-left-wr {
    	width: 100%;
	}
	.con-motion-graphic-wr .con-anim-right-wr {
	    width: 70%;
	    margin: 52px auto 0px;
	}
	.con-video-wr.con-space-wr .con-about-img {
    	width: 92%;
	}
	.con-page-content {
    	width: 92%;
	}
	.con-motion-graphic-wr .con-head-blk {
    	padding-bottom: 19px;
	}
	/* industries we serve */
	.inquiry-form-title h3 span:nth-child(2) {
		font-size: 33px;
		line-height: 43px;
	}
	.industries-form-blk {
		margin-bottom: 81px;
		padding: 40px 36px;
		border-radius: 20px;
	}
	.design-services-wr .perks-benefit-box, .con-technologies-wr .industries-slider-wr .slide-inner-space {
		border-radius: 25px;
		padding: 25px;
	}
	.testimonial-img-blk {
		right: -181px;
	}
	/* Menu */
	.header-menu-block {
		width: 74%;
		margin: 8px 0px 0px 3%;
	}
	.header-menu-block ul li {
		margin: 0px 0px 0px 6%;
	}
	.menu-close-icon {
		margin: 4px 0px;
	}
	.submenu li a > span.submenu-text-wr {
		width: 72%;
	}
	.digital-image-blk {
		width: 900px;
	}
	.digital-slider-area {
		width: 63%;
		top: 19px;
		padding: 0px 112px;
	}
	.con-ecom-list-wr:before {
		right: -9px;
	}
	.con-illustration-wr .con-anim-right-wr, .con-illustration-wr .con-anim-left-wr.left{
		width: 100%;
	}
	.con-illustration-wr .con-anim-left-wr.left{
		padding-left: 0;
		margin-bottom: 30px;
	}
	.con-illustration-wr:before{
		display: none;
	}
	.form-heading h4 {
	font-size: 32px;
		line-height: 40px;
	}
	.contact-blk a{
		font-size: 0;
	}
	.header-contact-information .header-contact-left, .header-contact-information .social-icon-form-left{
		width: 100%;
		text-align: center;
	}
	.header-contact-information .social-icon-form-left{
		margin-top: 30px;
	}
	.con-about-img img {
		margin: 0px auto 25px;
	}	.con-testimonial-thumbnail figcaption {
		position: relative;
		bottom: -30px;
	}.loader-event-img.twice {
		width: 340px;
	}#loader-content-wr h1 {
		font-size: 69px;
		line-height: 53px;
	}
	.loader-hanging-img img {
		width: 81%;
	}.loader-wave-container{
		width: 70%;
	}
	.counter-sub {
		font-size: 41px;
		line-height: 49px;
	}.numbers div, .counter-aniv {
		width: 385px;
	}@keyframes countNumber {
		0% {
			margin-left:0px
		}
		100% {
			margin-left:-3850px
		}
	  }
	  .loader-counter-container{
		width: 35%;
	  }
	  .marquee-container h3, .marquee-container h3 span {
		font-size: 16px;
	}.clinking-glass-img {
		width: 2.3%;
	}.animate-character {
		font-size: 42px;
		line-height: 43px;
	}.animate-char-wr{
		bottom: 38px;
		left: 37px;
	}.banner-image-blk.aniversary figure:nth-child(1) {
		transform: translateY(79%);
	}
	.aniversary-sparkle-collection-wr.top-right-sparkle {
		top: 46.5%;
	}.aniversary-sparkle-collection-wr.bottom-left-sparkle {
		bottom: -100%;
	}.aniversary-sparkle-collection-wr.ribbon {
		top: 94%;
		width: 8%;
	}.aniversary-sparkle-collection-wr.big-star {
		top: 58%;
	}.aniversary-sparkle-collection-wr.big-star.for-anim-top-right, .aniversary-sparkle-collection-wr.big-star.for-anim-top-left {
		top: 60%;
	}.aniversary-sparkle-collection-wr.exclaim {
		top: auto;
		bottom: -76%;
	}.aniversary-sparkle-collection-wr.big-star{
		width: 6%;
	}
	.aniversary-sparkle-collection-wr.big-star img{
		width: 100%;
	}.aniversary-sparkle-collection-wr.big-star.for-anim-top-left, .aniversary-sparkle-collection-wr.big-star.for-anim-bottom-left {
		left: 12%;
	}.loader-dot::before {
		transform: translate(97px,-89px);
	}.white-logo-img {
    width: 34%;
}.loader-holi-wr img{
	bottom: 0;
}
}
/*1024*/
@media only screen and (max-width: 1023px) {
	.con-full-stack-wr .con-full-wr, .con-left-right-wr.con-php-wr {
		margin-top: 14px;
	}
    /* Home Page */
    .logo-block {
    	width: 37%;
	}
	.contact-blk a {
	    font-size: 0;
	    line-height: 0;
	}
	.header-contact-content {
    	width: 60%;
	}
	.common-round-btn {
		padding: 18px 34px 18px 20px;
	}
	.common-round-btn::before {
		right: 8px;
	}
	h1 .large-head, .testimonial-head h3 {
		font-size: 94px;
		line-height: 137px;
	}
	.experience-list ul li {
	    font-size: 20px;
	    line-height: 24px;
	}
	.lets-talk-btn a {
		padding: 14px 24px;
	}
	.digital-image-blk .bx-controls-direction a.bx-prev {
    	left: -82px;
	}
	.digital-image-blk .bx-controls-direction a.bx-next:hover {
    	right: -83px;
	}
	.experience-list {
    	margin-bottom: 69px;
	}
	.get-started-content h4, .get-started-content ul.dynamic-txts {
	    font-size: 43px;
	    line-height: 57px;
	}
	.development-block {
    	padding: 43px 18px 37px 18px;
	}
	.common-heading span:nth-child(2) {
		font-size: 45px;
		line-height: 49px;
	}
	.industries-box-heading h4 {
	    font-size: 20px;
	    line-height: 25px;
	}
	.project-block h3 {
		font-size: 23px;
		line-height: 27px;
	}
	.counter-value h3, .counter-value span {
		font-size: 44px;
		line-height: 51px;
	}
	.counter-caption:before {
    	width: 36%;
	}
	.counter-caption {
    	padding: 31px 0px;
	}
	.testimonial-slide {
    	padding: 395px 0px 85px;
	}
	figure.testimonial-client-img {
	    width: 263px;
	    height: 500px;
	    top: -125px;
	    left: -84px;
	}
    .box-shadow-box {
      display: none;
    }
    .testimonial-img-blk {
        bottom: -113px;
        right: -158px;
    }
	figure.testimonial-client-img {
		width: 265px;
		height: 488px;
		top: -106px;
		left: -99px;
	}
    /* Life At Conative */
    .page-banner-heading, .con-head-blk h2 {
        font-size: 40px;
        line-height: 50px;
    }
    .con-head-blk p, .cits-inner-head-content p {
        font-size: 18px;
        line-height: 36px;
    }
    .con-life-description-blk p {
        font-size: 16px;
        line-height: 36px;
    }
    .con-life-heading-blk h3, .con-head-wr h4 {
        font-size: 34px;
        line-height: 34px;
    }
    /* Career */
    .build-career-img {
        margin-right: 2.5%;
        width: 9%;
    }
    .common-heading span:nth-child(2) {
        font-size: 50px;
    }
    .career-page-heading h3 {
        font-size: 40px;
        line-height: 50px;
    }
    .life-at-conative-wr .bx-controls-direction .bx-prev, .life-at-conative-wr .bx-controls-direction .bx-next {
        width: 64px;
        height: 64px;
    }
    .life-at-conative-wr .bx-controls-direction .bx-prev {
        left: -32px;
    }
    .life-at-conative-wr .bx-controls-direction .bx-next {
        right: -32px;
    }
    /*About Us*/
	.con-about-img {
    	width: 100%;
	}
	.con-page-content {
    	width: 100%;
	}
	.con-vison-content h3 span:nth-child(2), .con-vison-content h3 {
	    font-size: 28px;
	    line-height: 48px;
	}
	.con-journey-head h3 span:nth-child(2), .con-skill-title h3 span:nth-child(2), .con-team-built-head h3 span:nth-child(2) {
	    font-size: 41px;
	    line-height: 48px;
	}
	.con-journey-slide {
    	min-height: 542px;
	}
	.con-about-img img{
		margin: 0px auto 40px;
	}
	/*Contact Us*/
	.contact-page-form-in {
		width: 100%;
		margin-bottom: 50px;
	}
	.your-project-wr {
		padding-top: 61px;
	}
	.conatct-form-detail {
		padding: 66px 18px;
		width: 100%;
		margin: 50px 0px;
	}
	.con-combo-heading-blk {
    	margin-top: 29px;
	}
	.grow-with-us {
    	margin-top: 33px;
	}
	.conatct-form-detail-inner {
    	width: 100%;
	}
	.map-dots li:first-child {
    	top: 60%;
    	left: 25%;
	}
	.location-bubble {
    	padding: 18px 14px;
	}
	.contact-page-form-inner {
    	width: 91%;
	}
	.contact-form-left-heading a {
		font-size: 27px;
		line-height: 28px;
	}
	.map-dots li:first-child .location-bubble {
	    top: -141px;
	    left: -41px;
	}
	.map-dots li:nth-child(2) {
	    bottom: 67%;
	    right: 32.4%;
	}
	.contact-page-form-wr {
		margin-top: 30px;
	}
	/*Testimonial*/
	.testimonial-back-blk p {
    	font-size: 17px;
    	line-height: 30px;
	}
	.con-testimonial-slider-wr{
		padding: 80px 0px;
	}
	/*Enquiry Form*/
	.enquiry-form-content {
    	width: 87%;
	}
	/* Portfolio */
	.post-grid-blk a {
		height: 198px;
	}
	/* website single */
	.con-left-wr {
		width: 100%;
	}
	.con-right-wr {
		width: 100%;
		margin: 51px 0px;
	}
	/*Services page*/
	section.con-web-app-wr .con-overlay-title {
    	width: 67%;
	}
	.con-full-wr .con-head-blk.con-page-content {
	    padding: 0px 22px 1px;
	    margin-bottom: -54px;
	    width: 80%;
	}
	.circle-fill-btn {
	    font-size: 14px;
	    line-height: 23px;
	}
	/*Mobile App Development*/
	.con-flutter-app-wr .con-overlay-title {
	    margin-left: 0;
	    width: 65%;
	}
	.con-flutter-bottom-app-wr .con-tab-list-wr {
	    width: 100%;
	    padding: 25px 16px;
	}
	/*Servcies*/
	.con-woocom-wr .con-left-wr {
    	width: 100%;
	}
	.con-woocom-wr .con-left-wr img, .con-shopify-wr .con-right-wr img{width: auto;}
	.con-woocom-wr .con-right-wr {
	    width: 100%;
	    margin-bottom: 50px;
	}
	.con-shopify-wr {
    	margin-top: 80px;
	}
    .con-react-wr .con-page-content {
    	width: 100%;
	}
	.con-woocom-wr .con-left-wr , .con-shopify-wr .con-right-wr{
    	width: 100%;
    	position: unset;
	}
	.con-full-wr.con-big-com-wr {
    	margin-top: 80px;
	}
	.con-big-com-wr .con-big-com-blk.con-head-blk.con-page-content {
	    width: 100%;
	    padding: 0px 30px 34px;
	    margin: 0;
	}
	.con-work-service-wr {
    	padding: 62px 0px 152px;
	}
	.con-eccom-wr .con-blockquote-blk {
    	width: 90%;
	}
	.con-woocom-wr {
    	margin-top: 67px;
	}
	.con-shopify-wr .con-left-wr {
    	width: 100%;
	}
	.con-mangneto-wr {
    	margin-top: 53px;
	}
	.con-shopify-wr .con-right-wr {
    	margin-top: 57px;
	}
	.con-ecom-list-wr:before {
    	right: -31px;
	}
	.con-mangneto-wr .con-img-title-blk {
    	margin-bottom: 50px;
	}
	/*digital services*/
	.services-page-content-blk .con-about-img, .services-page-content-blk .con-head-blk {
	    margin: 0px auto;
	    width: 94%;
	}
	.con-digital-services-wr .con-anim-left-wr {
    	width: 51%;
	}
	.con-content-manage-wr .con-about-img {
    	width: 100%;
	}
	.con-content-manage-wr .con-page-content {
    	width: 100%;
	}
	.con-content-manage-wr .con-overlay-title {
    	width: 69%;
    	margin: -100px auto 0px 29%;
	}
	/*services*/
	section.con-graphic-design-wr {
    	padding: 46px 0px 58px;
	}
	.con-graphic-design-inner-wr .con-blockquote-blk p {
	    font-size: 26px;
	    line-height: 45px;
	}
	.con-logo-wr .con-left-right-wr .con-left-wr {
		margin-top: 17px;
	}
	.con-left-right-wr.con-ux-wr .con-left-wr, .con-logo-wr .con-left-right-wr .con-left-wr , .con-left-right-wr.con-ux-wr .con-right-wr , .con-logo-wr .con-left-right-wr .con-right-wr{
    	width: 100%;
	}
	.con-left-right-wr.con-ux-wr .con-left-wr img, .con-logo-wr .con-left-right-wr .con-left-wr img{
		width: auto;
	}
	.con-left-right-wr.con-ux-wr .con-right-wr {
	    margin-bottom: 50px;
	}
	.con-logo-wr .con-left-right-wr .con-right-wr {
		padding-right: 0;
		margin-top: 0;
	}
	.con-head-blk h4 {
		font-size: 26px;
		line-height: 31px;
	}
	/* .con-illustration-wr .con-anim-left-wr.left {
	    padding-left: 0;
	    width: 50%;
	} */
	.full-img-blk {
	    width: 75%;
	}
	/*animtions*/
	.con-animations-wr .con-blockquote-blk p {
	    font-size: 28px;
	    line-height: 50px;
	}
	.con-anim-left-right-wr {
	    padding-top: 31px;
	}
	.accordion-content {
		opacity: 0;
		max-height: 0;
		overflow: hidden;
		/* transition: opacity 500ms ease-in-out,margin 400ms ease-in-out, max-height 400ms ease-in-out; */
		/* transition: max-height 0.3s ease; */
	}
	.con-head-wr h4 {
	    font-size: 35px;
	    line-height: 44px;
	}
	.accordion-wrapper.active .accordion-content {
		opacity: 1;
		display: block;
		margin: 50px 0px;
		max-height: 1000px;
	}
	.con-video-wr.mobile-app-wr .con-about-img {
    	width: 93%;
	}
	.con-video-wr .con-overlay-title{
	    padding: 37px 70px 44px 46px;
	    width: 72%;
	    margin: -124px auto 0px 25%;
	}
	.gifs-list-wr .con-tab-list-wr li {
    	width: 40%;
	}
	.con-gifs-wr {
    	padding: 47px 0px 156px;
    }
    .con-inner-gifs-wr:before {
	    left: -247px;
	    background-size: 82%;
	}
	.con-other-service-wr {
    	padding: 80px 0px 123px;
	}
	/* menu */
	.submenu li a > span.submenu-text-wr {
		width: 68%;
	}
	nav.header-menu.menu_tabber , .header-menu-block{
		display: none;
	}
	.accordion-wrapper .accordion-head{
		display: block;
		margin-top: 25px;
	}
	/* .accordion-content {
		display: none;
		margin: 50px 0px;
	} */
	.menu_tabs .accordion-head a {
		padding: 20px;
		display: block;
		background-color: #f84525;
		color: #fff;
		border-radius: 15px;
		position: relative;
	}
	.accordion-head a{
		color: #000;
	}
	.menu_tabs.accordion-wrapper .accordion-head a:before {
		content: '+';
		width: 20px;
		height: 20px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 30px;
		font-size: 27px;
		color: #fff;
		font-weight: 400;
		font-family: "Poppins", sans-serif;
	}
	.accordion-wrapper .accordion-head a.active:before {
			content: "-";
	}
	.header-logo-block {
		width: 29%;
	}
	.header-top-wrapper {
		padding: 21px 0px;
	}
	.header-bottom-wrapper {
		padding-top: 18px;
	}
	.submenu-wrapper {
		margin-bottom: 50px;
	}
	.header-bottom-wrapper {
		padding-top: 18px;
		overflow-y: scroll;
		height: 550px;
		overflow-x: hidden;
	}
	.header-contact-information .header-contact-left, .header-contact-information .social-icon-form-left {
		width: 100%;
		text-align: center;
	}
	.header-contact-information .social-icon-form-left {
		margin-top: 30px;
	}
	ul.submenu li:nth-last-child(-n+3) {
		margin-bottom: 0;
	}
	.life-at-conative-slider .swiper-button-prev, .life-at-conative-slider .swiper-button-next {
		width: 65px;
		height: 65px;
		transform: translateY(-50%);
	}
	.life-at-conative-slider {
		padding: 0px 43px;
	}
	.con-react-wr.con-full-wr .con-about-img {
		width: 100%;
	}
	.other-services-list li {
		margin: 0px 0px 45px 50px;
	}
	section{overflow: hidden;}
	.con-card-blk:hover{
		transform: unset;
	}
	.enquiry-form-content, .build-career-popup-wrapper {
		width: 78.8%;
	}
	.con-right-wr.for-mob-web {
		margin: 51px 0px 31px;
	}	.conative-christmas-logo-container {
		width: 310px;
	}
	#loader-content-wr h1 {
		font-size: 60px;
		line-height: 48px;
	}.loader-event-img {
		left: -170px;
		width: 440px;
	}
	@keyframes sineWaveMove {
		0% {
		  left: -170px;
		  transform: translateY(0) translateX(0);
		}
		25% {
		  transform: translateY(50px) translateX(50px);
		}
		50% {
		  transform: translateY(0) translateX(100px);
		}
		75% {
		  transform: translateY(-50px) translateX(150px);
		}
		100% {
		  transform: translateY(0) translateX(200px);
		}
	  }@keyframes scrollAnimation {
		0% {
		  left: -350px;
		  transform: translateX(0);
		}
		50% {
		  transform: translateX(140vw); 
		}51%{
		  transform: translateX(140vw) scaleX(-1); 
		}
		100% {
		  left: -350px;
		  transform: translateX(0)  scaleX(-1); 
		}
	  }
	  .marquee-container h3, .marquee-container h3 span {
		font-size: 15px;
	}.loader-dot::before {
		transform: translate(93px,-86px);
	}
}
/*980*/
@media only screen and (max-width: 979px) {
	.con-page-content.class p{
		margin-top: 100px;
	}
	/* diwali */
	.diwali-decor-1a img {
		left: 58px;
	}
	.diwali-decor-1b img {
		top: -240px;
		left: 110px;
	}
	.diwali-decor-2a img {
		right: 58px;
	}
	.diwali-decor-2b img {
		top: -240px;
		right: 110px;
	}
    /* Home Page */
    header {
        padding-top: 31px;
    }
    .logo-block {
        width: 35%;
    }
    .header-contact-content {
        width: 58%;
    }
    .banner-content-blk , .features-description-blk, .get-started-content, .get-started-slider-area{
	    width: 100%;
	}
	.banner-content-blk {
	    margin: 30px 0px;
	}
	.banner-image-blk {
	    width: 93%;
	    right: 0;
	    margin: 0px auto;
	}	
	.banner-content-blk,  .banner-image-blk{
	    float: unset;
	}
	.features-description-blk {
	    margin: 33px 0px 0px;
	}
	.features-widget {
    	margin-bottom: 40px;
	}
	.experience-list {
    	margin-bottom: 47px;
	}
	.experience-list ul li {
	    font-size: 19px;
	    line-height: 25px;
	    padding: 0px 9px 0px 16px;
	    margin: 5px 0px;
	}
	.get-started-content {
	    padding: 46px 39px 47px;
	    position: unset;
	    transform: unset;
	}
	.development-blocks {
    	width: 100%;
	}
	.development-block h4, .development-block h4 a {
	    font-size: 20px;
	    line-height: 27px;
	}
    .common-heading span:nth-child(2) {
        font-size: 46px;
    }
    .development-block-icon {
        margin-top: 12px;
    }
	.industries-wr {
		padding: 71px 0 26px;
	}a
    .industries-box-heading {
        margin-top: 19px;
        min-height: 46px;
    }
    .industries-box:nth-child(4n) {
        margin-right: 0;
    }
    .industries-box:nth-child(5n) {
        margin-right: 4%;
    }
    .industries-box {
        width: 22%;
        margin: 0px 4% 37px 0;
        float:none;
        display: inline-block;
        vertical-align: middle;
    }
    .industries-box:nth-last-child(-n+1) {
        margin-right: 0;
    }
    .industries-btn {
        margin-top: 28px;
    }
    .our-project-heading.left {
        width: 100%;
    }
    .counter-blk {
        width: 50%;
    }
	.project-block {
		margin-bottom: 0;
	}
	.project-widget-area div > div > div:nth-child(2):not(.trigger-hover) {
		margin-top: -67px;
	}
	.project-widget-area {
		margin: 130px 0px 0px;
	}
    .counter-blk:nth-child(2n+1):before{display: none;}
    .testimonial-review-blk , .testimonial-media-blk {
        width: 100%;
    }
    .testimonial-img-blk {
        bottom: -94px;
        right: -229px;
        left: 0;
        margin: 0px auto;
        width: 37%;
    }
    .testimonial-review-blk.right {
        width: 90%;
        margin: 102px auto 0px;
    }
	.certification-slider-inner .swiper {
		padding: 0px 20px 73px;
	}
    .testimonial-widget-area .bx-controls-direction {
        bottom: 95px;
        left: auto;
        right: 0;
        margin: 0px auto;
        width: 142px;
    }
    .round-bg-btn:before {
        height: 50px;
        left: -26px;
        top: -14px;
        width: 50px;
    }
    .testimonial-caption p, .check-all-testimonial-btn a {
        margin-top: 14px;
    }
    .certification-heading.left {
        width: 100%;
    }
    .testimonial-slide {
        padding: 363px 0px 46px;
    }
    .testimonial-widget-area .bx-controls-direction {
        bottom: 5%;
    }
    .common-heading span:nth-child(2) {
        font-size: 39px;
        line-height: 42px;
        padding: 0px 20px;
    }   
    .our-project-heading.left h3 span:nth-child(2) {
        font-size: 41px;
        line-height: 53px;
    } 
    .common-heading span:nth-child(2) {
        font-size: 38px;
        line-height: 41px;
    }
    .features-description-blk {
        margin: 19px 0px 0px;
    }
    .footer-content-block{
        width: 100%;
        margin: 0;
    }
    .footer-our-services, .footer-our-company, .footer-industries-serve {
        width: 50%;
    }
	.footer-content-block.footer-industries-serve {
		width: 50%;
	}
    .office-address-inner {
        width: 100%;
    }
    .footer-content-block{
        margin-top: 30px;
    }
    .footer-content-block:first-child{
        margin-top: 0;
    }
    .footer-bottom {
        padding: 13px 0;
        width: 100%;
    }
    .office-address-inner {
        width: 100%;
    }
    .office-address {
        width: 100%;
        margin: 0;
        padding-top: 33px;
    }
    .office-address:before {
        width: 100%;
        height: 2px;
        bottom: -10px;
    }
    .footer-social-icon a {
        width: 65px;
        height: 65px;
        line-height: 65px;
    }
    .footer-social-icon.right {
        width: 100%;
    }
    .footer-copyright {
        margin-top: 8px;
        width: 100%;
    }
    .copyright-social-icon {
        padding: 14px 0;
    }
    .lets-talk-blk {
        width: 94%;
        padding: 41px 45px 51px;
		text-align: center;
    }
    .lets-talk-description {
        width: 100%;
    }
    .lets-talk-btn {
        width: 100%;
        text-align: center;
    }
    .footer-top {
        padding-top: 57px;
    }
    footer {
        padding-top: 176px;
    }
    /* Life At Conative */
    .con-alternate-blk:nth-child(even) .con-left-blk img, .con-alternate-blk:nth-child(odd) .con-left-blk img {
        margin: 0 auto;
    }
    .con-alternate-blk:nth-child(even) .con-left-blk, .con-alternate-blk:nth-child(odd) .con-left-blk {
        margin: 0;
        width: 100%;
        float: unset;
    }
    .con-alternate-blk:nth-child(odd) .con-right-blk, .con-alternate-blk:nth-child(even) .con-right-blk {
        float: unset;
        width: 100%;
        margin: 40px 0 0;
    }
    .con-left-blk::before, .con-left-blk::after {
        position: absolute;
        width: 430px;
        height: 430px;
    }
    .con-alternate-blk {
        margin-top: 100px;
    }
	.digital-image-blk {
		width: 700px;
	}
	.digital-slider-area {
		width: 71%;
		top: 13px;
		padding: 0px 112px;
	}
	.digital-slider .swiper-button-prev {
		left: -63px;
	}
	.digital-slider .swiper-button-next {
		right: -63px;
	}
    /* Career */
    .build-career-img{
        display: none;
    }
    .perks-benefit-box {
        width: 49%;
    }
    .perks-benefit-box:nth-child(2n + 1) {
        margin-left: unset;
    }
    /*About Us*/
	.con-about-wr .con-overlay-title {
	    width: 100%;
	    padding: 29px 0px;
	    margin: 0;
	}
	.con-space-wr {
    	padding: 73px 0px 57px;
	}
	.con-space-wr.con-eccom-wr {
		padding-bottom: 55px;
	}
	.con-skill-left {
	    width: 100%;
	    padding: 32px 0px 59px 0px;
	    float: unset;
	}
	.con-skill-right {
    	width: 100%;
    	float: unset;
	}
	.page-banner-heading, .con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
	    font-size: 36px;
	    line-height: 51px;
	}
	/*Our Team*/
	.con-team-content.con-overlay-title {
		margin: 13px 0px 0px;
		width: 100%;
	}
	.con-team-content-wr .con-about-img {
    	width: 100%;
	}
	.team-icon-title-wr .con-icon-title-blk {
    	width: 33%;
	}
	.team-icon-title-wr .con-icon-title-blk:nth-child(4n){
		border-right: 1px solid rgba(0, 0, 0, 0.1);
	}
	.team-icon-title-wr .con-icon-title-blk:nth-child(3n){border-right: 0;}
	.team-icon-title-wr .con-icon-title-blk:nth-last-child(2){
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);	
	}
	.team-icon-title-wr .con-icon-title-blk:nth-last-child(1){
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		border-right: 1px solid rgba(0, 0, 0, 0.1);
	}
	/*Testimonial*/
	.testimonial-back-blk p {
	    font-size: 17px;
	    line-height: 30px;
	}
	.con-head-blk p {
    	margin-top: 15px;
	}
	.testimonial-post-blk {
	    /* padding: 47px 30px 57px; */
	}
	.con-life-block {
		width: 100%;
		margin: 0px auto;
	}
	.popup-content{
		width: 90%;
	}
	.popup-video iframe, .video-block {
		width: 90%;
		height: 392px;
	}
	.video-block video{
		width: 100%;
		height: 100%;
	}
	.con-testimonial-slider {
		width: 77%;
		margin: 0px auto;
	}
	/*Enquiry Form*/
	.enquiry-form-inner {
    	width: 89%;
	}
	.enquiry-form-con {
    	margin-top: 25px;
	}
	.form-field {
	    margin-bottom: 12px;
	}
	/* Career Single */
	.career-business-development-blk h2 {
		font-size: 31px;
		line-height: 36px;
	}
	.careers-content-inner h3 {
		margin-top: 27px;
	}
	.conative-common-list ul li {
		font-size: 17px;
		line-height: 41px;
	}
	.career-apply-now-form {
		width: 98%;
	}
	/* 404 */
	section.error404-content .intro-text {
		width: 100%;
	}
	.post-tabs-wr {
		width: 100%;
		margin: 55px auto;
	}
	.post-grid-blk {
		width: 63%;
		float: unset;
		margin: 0px auto;
	}
	/* Website single */
	.con-sidebar-wr {
		width: 100%;
	}
	.website-single-page-content {
		width: 100%;
		margin: 0px auto 34px;
	}
	.subcat-inner-wr:before, .con-ecomm-web-content .con-services-left:before {
		height: 65%;
	}
	/*Services Pages*/
	.con-left-wr , .con-right-wr{
    	width: 100%;
	}
	.con-right-wr.right {
    	margin-top: 45px;
	}
	.con-flutter-bottom-app-wr .con-right-wr {
		right: 0;
		position: unset;
		width: 100%;
	}
	section.con-flutter-app-wr.con-space-wr {
		padding-bottom: 0;
	}
	.con-full-wr .con-head-blk.con-page-content {
	    margin-bottom: 24px;
	    width: 100%;
	}
	.con-htm-css-wr .con-left-wr {
    	width: 100%;
	}
	.con-htm-css-wr .con-left-wr img, .con-javascript-wr .con-left-wr img, .con-backend-wr .con-left-wr img, .con-node-wr .con-left-wr img{
		width: auto;
	}
	.con-htm-css-wr .con-right-wr {
    	width: 100%;
	}
	.con-html-css-wr h4 {
	    font-size: 22px;
	    line-height: 28px;
	}
	.con-javascript-wr .con-right-wr, .con-backend-wr .con-right-wr , .con-javascript-wr .con-left-wr, .con-backend-wr .con-left-wr, 
	.con-web-develop-design-wr .con-anim-left-right-wr .con-anim-left-wr, .con-web-develop-design-wr .con-anim-left-right-wr .con-anim-right-wr, 
	.con-node-wr .con-left-wr, .con-node-wr .con-right-wr{
    	width: 100%;
	}
	.con-backend-wr .con-left-wr{
		margin-top: 30px;
	}
	.page-banner-heading, .con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
	    font-size: 34px;
	    line-height: 49px;
	}
	.con-left-right-wr.con-javascript-wr , .con-node-wr .con-left-wr{
    	margin-top: 33px;
	}
	.con-backend-wr .con-right-wr {
    	padding: 46px 0px;
	}
	.con-web-develop-design-wr .con-anim-left-right-wr .con-anim-left-wr{
		margin-bottom: 45px;
	}
	.con-left-right-wr.con-node-wr {
    	margin-top: 26px;
	}
	.con-queries-form .form-submit-btn {
    	width: 100%;
	}
	.con-queries-form .enquiry-form-requirements {
    	width: 64%;
	}
	.con-page-content p {
	    font-size: 17px;
	    line-height: 40px;
	}
	.con-woocom-wr .con-right-wr .con-page-content p {
	    line-height: 40px;
	}
	.con-queries-wr {
	    padding: 48px 0px;
	}
	.form-submit-btn input {
    	padding: 16px 66px 16px 36px;
	}
	/*Mobile App Development*/
	.mobile-header-left-right-wr, .mobile-bottom-header-wr {
    	width: 100%;
	}
	.mobile-header-left-right-wr .con-page-content {
	    width: 100%;
		margin-top: 0;
	}
	.mobile-header-list, .con-angular-list {
	    width: 100%;
	}
	.mobile-header-list:before {
	    border-left: 18px solid transparent;
	    border-bottom: 30px solid #f8f8f8;
	    border-right: 18px solid transparent;
	    left: 0;
	    top: -15px;
	    transform: translateY(-50%);
	    right: 0;
	    margin: 0px auto;	
	}
	section.con-mobile-app-wr .con-overlay-title {
	    width: 100%;
	    padding: 26px 0px 7px 0px;
		margin: 60px auto 0;
	}
	.con-react-service-wr .con-left-wr, .con-android-develop-wr .con-right-wr.right  , .con-react-service-wr .con-right-wr, .con-android-develop-wr .con-left-wr.left{
    	width: 100%;
	}
	.con-android-develop-wr .con-right-wr.right{
		margin-bottom: 50px;
	}
	.con-android-develop-wr .con-left-wr.left img{
		width: auto;
	}
	.con-queries-form .form-field {
		/* margin-right: 5%; */
	}
	.con-flutter-app-wr .con-overlay-title {
	    width: 100%;
	    margin: 0px;
	}
	.con-flutter-bottom-app-wr .con-left-wr {
    	width: 100%;
	}
	/*Ecommerce*/
	.con-page-content p {
	    font-size: 16px;
	    line-height: 37px;	
	}
	.con-woocom-wr .con-right-wr .con-page-content p {
	    line-height: 39px;
	}
	.services-page-content-blk .con-page-content, .con-blockquote-blk {
	    width: 100%;
		margin: 50px 0;
	}
	.con-ecom-list-wr ul li {
		width: 100%;
	}
	.con-woocom-wr {
    	margin-top: 26px;
	}
	.con-ecom-list-wr {
    	padding: 33px 14px 36px 34px;
	}
	.con-ecom-list-wr:before{
		display: none;
	}
	section.con-space-wr.con-android-develop-wr {
    	padding: 20px 0px;
	}
	/*digital solutions*/
	.con-digital-services-wr .con-anim-left-wr {
    	width: 100%;
	}
	.con-digital-services-wr .con-anim-right-wr {
	    width: 100%;
	    margin-top: 50px;
	}
	.con-motion-list-wr {
	    padding: 34px 37px 42px 36.1px;
	    border-radius: 29px;
	}
	/*digital solutions*/
	.image-content-wr {
	    border-radius: 7px;
	    padding: 24px 18px;
	    margin-top: 36px;
	}
	.icon-content-blk .icon-blk.left {
		width: 100%;
	}
	.content-blk {
	    width: 100%;
	    margin: 15px 0px 0px 0%;
	}
	.icon-content-blk {
	    width: 100%;
	    display: block;
	    margin: 35px auto 0px;
	}
	.icon-content-blk:first-child{
	    margin: 0px auto 0px;
	}
	.icon-content-blk:before{
	    width: 100%;
	    height: 1px;
	    right: 0;
	    top: auto;
	    transform: unset;
	    bottom: -25px;
	}
	.image-content-wr {
	    width: 63%;
	    margin: 40px auto 0px;
	}
	.services-page-content-blk .con-img-title-blk {
    	margin-bottom: 43px;
	}
	section.con-digital-services-wr {
    	padding: 76px 0px 86px;
	}
	.con-digital-services-wr .con-left-wr {
    	width: 100%;
	}
	section.con-digital-services-wr .con-right-wr.right {
    	width: 100%;
    	margin-bottom: 50px;
	}
	.social-media-marketing-content ul li {
    	width: 48%;
    }
    .social-media-marketing-content ul li:nth-child(2n+1){
    	margin-left: 0;
    }
    section.con-digital-services-wr .con-left-right-wr {
    	margin-top: 37px;
	}
	.con-work-service-wr {
    	padding: 45px 0px 138px;
	}
	/*designing*/
	section.con-ui-design-wr.con-space-wr {
 	   padding: 72px 0px 84px;
	}		
	.con-print_material .con-about-img {
    	width: 100%;
	}
	section.con-logo-wr {
    	padding: 79px 0px;
	}
	section.con-logo-wr:before {
    	width: 100%;
	}
	.con-logo-wr .white-ring-circle{display: none;}
	.product-braning-inner-wr .con-page-content {
    	width: 100%;
	}
	.con-customers-wr.con-tab-list-wr, .product-braning-inner-wr .con-page-content {
    	padding: 29px 27px 30px 29px;
	}
	.con-anim-left-right-wr {
    	padding-top: 75px;
	}
	.con-illustration-wr .con-anim-left-wr.left {
    	width: 100%;
	}
	.con-illustration-wr .con-anim-right-wr {
    	width: 100%;
    	margin-top: 41px;
	}
	.con-illustration-wr:before{display: none;}
	.con-tab-list-wr {
    	margin-top: 0;
	}
	.con-print_material .con-overlay-title {
	    width: 100%;
	    margin: 11px auto 0px 0%;
	    padding: 18px 62px 26px 39px;
	}
	.con-print-content h3 {
	    margin-top: 36px;
	}
	section.con-ui-design-wr.con-space-wr .con-about-img {
	    width: 100%;
	}
	.con-ui-design-wr .con-overlay-title {
	    width: 100%;
		margin: 60px auto 0;
	    padding: 30px 20px 21px;
	}
	.con-customers-wr.con-tab-list-wr h4 {
    	font-size: 26px;
	    line-height: 37px;
	}
	.con-customers-wr.con-tab-list-wr, .con-ui-design-wr .con-page-content, .product-braning-inner-wr .con-page-content {
    	width: 100%;
	}
	.con-tab-list-wr li:before {
    	top: 7px;
	}
	.con-tab-list-wr li {
	    padding: 10px 0px 10px 47px;
	    font-size: 17px;
	    line-height: 25px;
	    margin-bottom: 17px;
	}
	.con-logo-solution-wr, .con-full-width-wr.con-ue-wr {
    	width: 100%;
	}
	.con-graphic-design-inner-wr .con-blockquote-blk p {
	    font-size: 22px;
	    line-height: 35px;
	}
	.con-blockquote-blk blockquote, .con-blocquote-wr ul {
    	padding-left: 22px;
	}
	.con-graphic-design-inner-wr .con-blockquote-blk {
    	margin: 33px 0px 17px;
	}
	section.con-logo-wr {
    	padding: 61px 0px;
	}
	.con-angular-list .con-tab-list-wr li {
    	margin-bottom: 13px;
	}
	.con-print_material {
    	margin-top: 93px;
	}
	.con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
	    font-size: 32px;
	    line-height: 47px;
	}
	.full-img-blk {
    	width: 100%;
    }
    /*animations*/
    .con-animations-wr .con-blockquote-blk p {
	    font-size: 24px;
	    line-height: 40px;
	}
	.con-animations-wr {
    	padding: 72px 0px 65px;
	}
	figure.con-motion-img img {
	    object-fit: cover;
	    height: 253px;
	    object-position: left;
	}
	.con-motion-graphic-wr .con-anim-left-wr{
		margin-top: 0;
	}
	span.con-logo-process {
	    padding: 17px 12px;
	    margin: 0px 0px 31px 8px;
	    font-size: 14px;
	    line-height: 17px;
	}
	.con-logo-process-blk {
	    margin: 45px auto 0px;
	}
	.con-motion-list-wr h5 {
	    font-size: 26px;
	    line-height: 36px;
	}
	.con-motion-graphic-wr .con-anim-right-wr {
	    width: 94%;
	    margin: 46px auto 0px;
	}
	.con-video-wr.con-space-wr .con-about-img {
    	width: 100%;
	}
	section.con-video-wr.mobile-app-wr {
    	padding-top: 80px;
    }
    .con-video-wr .con-overlay-title {
	    padding: 34px 66px 34px 46px;
	    width: 100%;
	    margin: 0;
	    text-align: left;
	}
	.explainer-video-wr .con-anim-left-right-wr {
    	padding-top: 0;
	}
	.con-inner-gifs-wr:before{
		display: none;
	}
	.con-inner-gifs-wr {
    	width: 100%;
    	padding: 0px;
	}
	.con-gifs-wr:before {
    	width: 100%;
	}
	.con-anim-left-right-wr {
    	padding-top: 41px;
	}
	.con-animations-content-wr {
    	padding-top: 79px;
	}
	.con-head-wr h4 {
    	font-size: 28px;
		line-height: 36px;
	}
	.con-video-wr.mobile-app-wr .con-about-img {
    	width: 100%;
	}
	/* industries */
	.banner-img-blk {
		height: 350px;
	}
	.banner-img-blk img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 48% 0%;
	}
	.banner-img-overlay {
		width: 88%;
		left: 0;
		right: 0;
		margin: 0px auto;
	}
	.mockup-banner-img {
		width: 57%;
	}
	.con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 38px;
		line-height: 49px;
	}
	.con-icon-title-blk {
		width: 42%;
	}
	.company-branding-wr .testimonial-head h3 {
		font-size: 143px;
		line-height: 272px;
	}
	.con-technologies-wr .con-icon-title-blk {
		width: 31%;
		margin: 0px 0px 0px 2%;
	}
	.con-technologies-wr .design-sol-tabber-link li a {
		padding: 16px 10px;
	}
	.industries-left-blk {
		width: 100%;
	}
	.industries-right-blk {
		position: relative;
		width: 100%;
	}
	.industries-slider-inner {
		padding: 5px 14px 111px;
	}
	.get-started-slide .swiper-pagination {
		bottom: 50px;
	}
	/* Menu */
	.submenu li a > span.submenu-text-wr {
		width: 68%;
		margin-left: 6px;
	}
	.header-bottom-info-wrap h3 {
		width: 100%;
		font-size: 27px;
		line-height: 40px;
		margin-bottom: 30px;
		text-align: center;
	}
	.header-bottom-info-wrap a {
		width: 40%;
		float: unset;
		margin: 0px auto;
		display: block;
	}
	.testimonial-slider .swiper-button-next, .testimonial-slider .swiper-button-prev {
		transform: translateY(-119%);
	}
	.testimonial-slider .swiper-button-prev {
		margin-left: 72%;
	}
	.testimonial-slider .swiper-button-next {
		margin: -52px 0px 0px 88%;
	}
	.life-at-conative-wr {
		padding: 45px 0 150px;
	}
	.con-testimonial-thumbnail img {
		opacity: 1;
	}
	.get-started-blk {
		width: 66%;
		margin: 0px auto;
		text-align: center;
	}
	.get-started-content ul.dynamic-txts {
        width: 53%;
        margin: 8px auto 0px;
        top: 0;
        display: block;
    }
	.con-node-wr .con-left-wr:before{left: 0;}
	.con-jquery-wr h3 {
		background-position: 126px 100%;
	}
	.con-react-wr.con-full-wr.con-mangneto-wr .con-about-img {
		width: 100%;
	}
	.con-other-service-wr.con-animation-other-services {
		padding: 0px 0px 150px;
	}
	.react-lib-wr.image-content-wr .icon-content-blk:nth-last-child(1) , .react-lib-wr.image-content-wr .icon-content-blk {
		width: 48%;
	}
	.react-lib-wr.image-content-wr .icon-content-blk {
		margin:15px 0px 0px 2%;
		display: inline-block;
		vertical-align: top;
		min-height: 60px;
	}
	.react-lib-wr.image-content-wr .icon-content-blk:before {
		height: 100%;
		right: 0;
		width: 1px;
		bottom: 0px;
	}
	.con-journey-slider .swiper-wrapper {
		margin-bottom: 19px;
	}
	.sitemap-inner-wrapper {
		width: 90%;
	}
	.sitemap-main-wrapper .ring-wr{
		display: none;
	}
	.sitemap-links-wrapper ul li a {
		font-size: 18px;
		line-height: 25px;
	}
	.sitemap-links-wrapper {
		margin-top: 33px;
	}
	.conative-common-list ul li {
		padding: 0px 18px 0px 31px;
	}
	.sitemap-inner-wrapper {
		padding: 40px 28px;
	}
	.image-loop {
		height: 70px;
	}
	.con-about-img img{
		margin: 0px auto -90px;
	}
	.map-dots li:first-child:hover .location-bubble {
		top: -95px;
	}.con-right-wr.for-mob-web {
		margin: 51px 0px -9px;
	}.common-heading.anim{
		padding-bottom: 30px;
	}.loader-event-img {
		left: -190px;
		width: 380px;
	}@keyframes sineWaveMove {
		0% {
		  left: -190px;
		  transform: translateY(0) translateX(0);
		}
		25% {
		  transform: translateY(50px) translateX(50px);
		}
		50% {
		  transform: translateY(0) translateX(100px);
		}
		75% {
		  transform: translateY(-50px) translateX(150px);
		}
		100% {
		  transform: translateY(0) translateX(200px);
		}
	  }
	  .loader-event-img.twice {
		width: 350px;
	}.conative-christmas-logo-container {
		width: 290px;
	}@keyframes scrollAnimation {
		0% {
		  left: -350px;
		  transform: translateX(0);
		}
		50% {
		  transform: translateX(160vw); 
		}51%{
		  transform: translateX(160vw) scaleX(-1); 
		}
		100% {
		  left: -350px;
		  transform: translateX(0)  scaleX(-1); 
		}
	  }
	  .counter-sub {
		font-size: 27px;
		line-height: 28px;
	}.numbers div, .counter-aniv{
		height: 102px;
	}
	.numbers div {
		font-size: 87px;
		line-height: 120px;
	}
	.loader-counter-container .counter-suffix {
    font-size: 43px;
	}.loader-counter-container{
		width: 44%;
	}.banner-image-blk.aniversary figure:nth-child(1) {
		transform: translateY(-19%);
		}	.animate-character {
		font-size: 32px;
		line-height: 33px;
	}
	.animate-char-wr {
		bottom: 28px;
		left: 30px;
	}
	.aniversary-sparkle-collection-wr.top-right-sparkle {
		top: 8.5%;
	}.aniversary-sparkle-collection-wr.bottom-left-sparkle {
		bottom: 3%;
	}.aniversary-sparkle-collection-wr.ribbon {
		top: 42%;
	}.aniversary-sparkle-collection-wr.big-star {
		top: 18%;
	}.aniversary-sparkle-collection-wr.big-star.for-anim-top-right, .aniversary-sparkle-collection-wr.big-star.for-anim-top-left {
		top: -30%;
	}.aniversary-sparkle-collection-wr.exclaim {
		bottom: 10%;
		top: auto;
	}.aniversary-sparkle-collection-wr.ribbon {
		top: -8%;
	}.aniversary-sparkle-collection-wr.top-right-sparkle {
		right: -1%;
	}.marquee-container h3 span {
		padding: 0 3px;
	}.marquee-container h3, .marquee-container h3 span {
		font-size: 10px;
	}.marquee-wr{
		padding: 0;
	}.loader-dot::before {
		transform: translate(72px,-69px);
	}.marquee-container .aniv-btn-inner {
		font-size: 12px;
		padding: 6px 37px 6px 14px;
	}.marquee-container h3 .btn {
		right: 3%;
		top: -23px;
	}.white-logo-img {
    width: 44%;
}
.loader-holi-wr img:nth-child(1) {
    height: 100%;
}
}
/*768*/
@media only screen and (max-width: 767px) {
	.con-page-content.class p{
		margin-top:0;
	}
	.con-react-wr .con-overlay-title {
		padding: 23px 30px 20px 47px;
	}
	.con-space-wr.con-android-develop-wr .con-tab-list-wr li {
		font-size: 15px;
	}
	.con-tab-list-wr {
		margin-top: 25px;
		margin-bottom: -39px;
	}
    /* Home Page*/
	.industries-heading h4 {
		margin-bottom: 25px;
	}
    .header-contact-content {
        width: 47%;
        margin: 20px 0px;
    }
    .our-work-blk {
        display: none;
    }
    .logo-block {
        width: 42%;
    }
    .banner-content-blk {
        margin: 0px 0px 25px;
        text-align: center;
    }
    .banner-content {
        margin-top: 12px;
    }    
    .digital-section-wr {
        margin-top: 74px;
    }
    .digital-content-area {
        text-align: center;
    }
    .get-started-content h4 strong {
        font-family: "Poppins", sans-serif;
        margin-left: 13px;
    }
    .get-started-content h4 {
        display: block;
        width: 100%;
    }
    .get-started-content h4 br:first-child {
        display: none;
    }
    .get-started-blk {
        position: relative;
        text-align: center;
	}
    .common-heading span:nth-child(2) {
        font-size: 38px;
        line-height: 43px;
    }
    .development-block {
        width: 50%;
        padding: 35px 18px 23px 15px;
        float: none;
        display: inline-block;
        vertical-align: top;
        border-top: 1px solid rgba(227, 227, 227, 0.8);
    }
    .development-block:nth-child(3n + 1) {
        border-left: 1px solid rgba(227, 227, 227, 1);
    }
    .development-block:nth-child(2n + 1){
        margin-left: unset;
    }
    .development-block-icon {
        margin-top: 0;
    }
    .development-block:nth-child(-n+2) {
        border-top: 0;
    }
    .development-block:nth-child(2n+1) {
        border-left: 0;
    }
    .industries-box{
        margin: 0;
    }
    .industries-wr .bx-wrapper .bx-pager-item, .our-project-wr .bx-wrapper .bx-pager-item {
        display: inline-block;
        vertical-align: middle;
        margin-left: 9px;
    }
    .industries-wr .bx-wrapper .bx-pager-item:first-child, .our-project-wr .bx-wrapper .bx-pager-item:first-child{
        margin-left: 0;
    }
    .industries-wr .bx-wrapper .bx-pager-item a, .our-project-wr .bx-wrapper .bx-pager-item a {
        font-size: 0;
        line-height: 0;
        width: 15px;
        height: 15px;
        border: 2px solid #fff;
        border-radius: 50%;
    }
    .industries-boxes {
        margin-top: 0;
    }
    .industries-wr .bx-wrapper, .our-project-wr .bx-wrapper {
        margin: 50px 0px;
    }
    .industries-wr .bx-wrapper .bx-controls.bx-has-pager, .our-project-wr .bx-wrapper .bx-controls.bx-has-pager{
        margin-top: 10px;
    }
    .industries-wr .bx-wrapper .bx-pager-item a.bx-pager-link.active {
        background-color: #fff;
    }
    .project-widget-area .trigger-hover {
        margin: 0;
    }
    .trigger-hover:nth-child(2n-1), .trigger-hover:nth-child(3n-1){
        top: 0 !important;
    }
	.project-widget-area {
		margin: 100px 0px 0px;
	}
    .our-project-wr .trigger-hover {
        margin-left: 0px !important;
    }
    .our-project-wr .bx-wrapper .bx-pager-item a.bx-pager-link{
        border-color: #f84525;
    }
    .our-project-wr .bx-wrapper .bx-pager-item a.bx-pager-link.active{
        background-color: #f84525;;
    }
    .our-project-wr {
        padding: 70px 0 51px;
        text-align: center;
    }
    .our-project-wr .bx-wrapper{
        margin: 50px 0px 0px;
    }
    .trigger-hover:nth-child(2n-1),.our-project-wr .trigger-hover {
        top: -100px !important;
    }
    .project-block {
        margin-bottom: 0;
    }
    .our-client-wr {
        padding: 88px 0px 83px;
    }
    .testimonial-review-blk.right {
        width: 95%;
    }
	.digital-bg-img img {
		height: 479px;
		object-fit: cover;
		object-position: center;
	}
	.digital-image-blk {
		width: 550px;
	}
	.digital-slider-area {
		width: 99%;
		top: 18px;
		padding: 0px 66px;
		left: 7px;
	}
    /* Life At Conative */
    .con-life-heading-blk h3, .con-head-wr h4 {
        font-size: 30px;
        line-height: 34px;
    }
    .page-banner-heading, .con-head-blk h2 {
        font-size: 32px;
        line-height: 42px;
    }
    .con-head-blk p, .cits-inner-head-content p {
        font-size: 17px;
        line-height: 32px;
    }
    .page-banner-wr {
        background-position: 95% 100%;
    }
    .client-say-content {
		width: 90%;
		margin-top: 12px;
	}
    .testimonial-review-blk p, .client-say-content p {
        font-size: 20px;
        line-height: 38px;
    }
    figure.testimonial-client-img {
        width: 221px;
        height: 347px;
        top: -130px;
        left: -86px;
    }
    /* Career */
    .common-heading span:nth-child(2) {
        font-size: 42px;
        line-height: 44px;
    }
    .build-career-all-blocks {
        padding-top: 60px;
    }
    .build-career-text {
        width: 90%;
    }
    .build-career-wr {
        padding: 80px 0 60px;
    }
    .career-page-heading h3 {
        font-size: 36px;
        line-height: 46px;
    }
    .perks-benefit-box {
        padding: 40px 49px 0 48px;
        min-height: 210px;
        margin: 0 0 32px 1.6%;
    }
    /*About Us*/
	.con-head-blk p {
	    margin-top: 25px;
	    line-height: 39px;
	}
	.con-choose-wr .con-card-blk {
	    display: block;
	    margin: 15px auto 0px;
	    width: 90%;
	}
	.con-skill-right {
		text-align: center;
	}
	.con-skill-blk {
		margin: 0;
		display: inline-block;
		vertical-align: middle;
		float: none;
		padding: 20px 10px;
		width: 29.5%;
		min-height: 133px;
		margin: 0px 0px 20px 3%;
	}
	.con-vison-content h3 span:nth-child(2), .con-vison-content h3 {
	    font-size: 26px;
	    line-height: 45px;
	}
	.con-journey-head h3 span:nth-child(2), .con-skill-title h3 span:nth-child(2), .con-team-built-head h3 span:nth-child(2) {
	    font-size: 38px;
	    line-height: 43px;	
	}
	.con-journey-slider-wr {
    	padding: 39px 0px 60px;
	}
	.con-contact-card.con-card {
	    width: 100%;
	    padding: 44px 70px 91px;
	}
	.con-overlay-title h2 span:nth-child(2) {
	    font-size: 40px;
	    line-height: 46px;
	}
	.con-img-title-blk {
    	margin-bottom: 11px;
	}
	.con-vison-wr {
    	padding: 73px 0px 58px;
	}
	.page-banner-heading, .con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
	    font-size: 38px;
	    line-height: 44px;
	}
	section.con-skills-wr .bx-wrapper .bx-controls-direction a {
	    color: #000;
	    margin-left: 35px;
	}
	section.con-skills-wr .bx-wrapper .bx-controls-direction {
    	text-align: center;
	    margin-top: 32px;
	}
	.con-skill-left {
    	padding: 27px 0px 36px 0px;
	}
	.con-skills-wr {
    	padding-bottom: 118px;
	}
	.con-journey-slide:hover .con-slide-overlay, .active-slide .con-slide-overlay {
	    opacity: 1;
	    visibility: visible;
	}
	.con-journey-slide {
    	min-height: 500px;
	}
	/*Contact Us*/
	.location-icon {
	    width: 30%;
	    margin-right: 0;
	    margin-bottom: 12px;
	}
	.location-bubble {
    	width: 192px;
	}
	.location-address {
    	width: 95%;
	}
	.map-dots li:first-child .location-bubble {
	    top: -165px;
	    left: -41px;
	}
	.location-bubble {
    	top: -243px;
	}

	.location-address p br {
    	display: none;
	}
	.map-dots li:nth-child(2) {
		bottom: 31%;
		right: 28.4%;
	}
	/*Our Team*/
	.con-team-content h2 {
	    font-size: 31px;
	    line-height: 36px;
	}
	.con-team-content.con-overlay-title {
    	padding: 26px 20px;
	}
	section.con-team-built-wr {
    	padding-bottom: 70px;
	}
	.con-team-contact-right figure::before {
	    top: -94px;
	    left: -99px;
	    width: 188px;
	    height: 185px;
	}
	/*Testimonial Slider*/
	.con-testimonial-slider {
		width: 100%;
	}
	.play-btn-icon {
		width: 60px;
		height: 60px;
	}
	.play-btn-icon i {
		font-size: 20px;
		line-height: 60px;
	}
	.testimonial-video-play-btn {
		top: 15px;
		right: 7px;
	}
	.play-btn-icon:before, .play-btn-icon:after {
		width: 60%;
		height: 60%;
	}
	.play-btn-icon:before {
		padding: 20px;
		left: -8px;
		top: -11px;
	}
	.play-btn-icon:after {
		padding: 40px;
		left: -23px;
		top: -24px;
		width: 38%;
		height: 40%;
	}
	.con-testimonial-slider-wr{
		padding: 0;
	}
	.con-testimonial-thumbnail::after {
    	height: 100%;
	}
	.con-testimonial-thumbnail figcaption {
		padding: 25px;
		background-color: #000;
		position: relative;
	}
	.testimonial-slide-title h4, .post-tabs-link li a{
		font-size: 22px;
		line-height: 26px;
	}
	/*Enquiry Form*/
	.form-heading h4 {
	    font-size: 34px;
	    line-height: 39px;
	}
	/* Career Single */
	.career-business-development-wr {
		padding: 80px 0 164px;
	}
	/* Portfolio */
	.post-grid-blk a {
		height: 178px;
	}
	.post-grid-blk {
		width: 74%;
	}
	.post-list-blk:nth-child(even) .post-list-head {
		left: 24px;
	}
	.post-list-blk:nth-child(odd) .post-list-head {
		right: 24px;
	}
	.page-banner-heading, .con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
		font-size: 27px;
		line-height: 35px;
	}
	.post-list-head h4 {
		margin-bottom: 20px;
	}
	/* logo design */
	.subcat-col {
		width: 29%;
		margin: 0px 0% 40px 3%;
	}
	div#logoTab .subcat-col:nth-child(3n) {
		margin-right: 0;
		margin-top: 12%;

	}
	.subcat-inner-wr:before, .con-ecomm-web-content .con-services-left:before {
		left: -20%;
		width: 378%;
	}
	.subcat-inner-wr:nth-child(2n):before {
		right: -29%;
	}
	.subcat-inner-wr:after{
		display: none;
	}
	.subcat-inner-wr {
		margin-top: 62px;
	}
	.four-col-wr .subcat-col:nth-child(2), .four-col-wr .subcat-col:nth-child(3) {
		width: 33%;
	}
	.four-col-wr .subcat-col:first-child, .four-col-wr .subcat-col:nth-child(4) {
		width: 60%;
	}
	/* brochure */
	.two-col-wr .subcat-col:first-child, .two-col-wr .subcat-col:nth-child(2) {
		width: 46.5%;
	}
	.subcat-inner-wr:nth-child(2n) {
		text-align: center;
	}
	/* corporate brading */
	.one-col-wr .subcat-col:nth-child(1) {
		width: 100%;
	}
	/*Services pages*/
	.con-queries-form .form-field {
		width: 47%;
		margin-right: 5%;
	}
	.queries-form .enquiry-form-requirements {
    	width: 100%;
	}
	.enquiry-upload {
    	width: 100%;
    	margin: 39px 0px 0px 0px;
	}
	section.con-web-app-wr .con-overlay-title {
	    width: 100%;
	    margin: 0px auto;
	    padding: 30px 0px;
	}
	.enquiry-form-email.form-field, .enquiry-form-select.form-field {
    	margin-right: 0;
	}
	.con-queries-form {
		width: 94%;
		margin: 0px auto;
	}
	.con-full-wr.con-php-list-wr ul {
    	flex-flow: column;
	}
	.con-why-code-wr .con-left-wr {
    	width: 100%;
    	margin-bottom: 0;
	}
	.con-why-code-wr .con-right-wr , .con-larvel-wr .con-left-wr, .con-larvel-wr .con-right-wr{
    	width: 100%;
	}
	.con-react-wr .con-overlay-title {
	    width: 100%;
	    margin: 61px auto 0;
	}
	.con-react-head-wr.left {
    	width: 100%;
	}
	.con-react-list-wr.con-tab-list-wr.right {
	    width: 100%;
	    margin: 26px auto 0px;
	}
	.con-larvel-wr {
    	padding: 30px 39px 41px 37px;
	}
	.page-banner-heading, .con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
		font-size: 34px;
		line-height: 41px;
	}
	.grow-with-us p, .con-html-css-wr .con-blockquote-blk blockquote p {
		font-size: 17px;
		line-height: 37px;
	}
	.con-blockquote-blk blockquote, .con-blocquote-wr ul {
    	padding-left: 19px;
	    border-left: 4px solid #f84525;
	}
	.con-queries-form .form-field {
		width: 100%;
	}
	/*ecommerce*/
	.con-eccom-wr .con-overlay-title {
	    width: 100%;
	    margin: 68px auto 0px 0%;
	    padding: 21px 16px;
	}
	.con-eccom-wr .con-blockquote-blk {
	    width: 98%;
	}
	.con-blockquote-blk blockquote p {
    	font-size: 21px;
	    line-height: 43px;
	}
	.con-eccom-wr .con-blockquote-blk blockquote {
    	padding-left: 25px;
	}
	.con-woocom-wr {
	    margin-top: 39px;
	}
	.con-tab-list-wr li {
	    font-size: 16px;
	    line-height: 18px;
	    margin-bottom: 16px;
	}
	.other-services-list li:first-child {
	    margin-left: 0;
	}
	.services-page-content-blk .con-img-title-blk {
    	margin-bottom: 36px;
	}
	.con-blockquote-blk {
	    margin: 0px auto 41px;
	}
	.react-lib-wr.image-content-wr .icon-content-blk {
	    width: 46%;
	    margin: 0px 0px 30px 2%;
	}
	.react-lib-wr.image-content-wr .icon-content-blk:nth-child(2n+1){
		margin-left: 0;
	}
	.react-lib-wr.image-content-wr{
		text-align: left;
	}
	.con-flutter-bottom-app-wr .con-tab-list-wr li{vertical-align: top;}
	.con-cross-inner-plateform-wr {
	    padding: 41px 28px;
	    background-color: #f8f8f8;
	    border-radius: 15px;
	}
	.con-digital-services-wr .con-anim-left-right-wr, .con-cross-outer-wr {
	    width: 100%;
	}
	.con-cross-image-blk {
	    border-radius: 15px;
	    overflow: hidden;
	    margin: 20px auto 0px;
	    width: 100%;
	}
	/*digital solutions*/
	.social-media-marketing-content ul li span.list-title {
    	width: 76%;
	}
	/* industries we serve */
	.company-branding-wr .testimonial-head h3 {
		font-size: 100px;
		line-height: 254px;
	}
	.con-technologies-banner {
		padding: 45px 0px 83px;
	}
	.con-technologies-wr .design-sol-tabber-link li {
		width: 31%;
		margin-left: 3%;
	}
	.design-sol-tabber-link li a {
		font-size: 16px;
		line-height: 21px;
	}
	.con-technologies-wr .con-tect-tab {
		width: 98%;
		padding: 38px 30px;
	}
	.design-sol-tabber-link {
		margin-bottom: 52px;
	}
	.con-technologies-wr .con-head-blk h4 {
		margin-top: 23px;
		font-size: 19px;
		line-height: 27px;
	}
	.industries-enquiry-form-blk {
		margin: 86px auto 0px;
	}
	.industries-slider {
		width: 69%;
		margin: 0px auto;
	}
	.industries-slider-wr .inquiry-form-title.common-heading {
		padding-bottom: 34px;
		text-align: center;
	}
	.inquiry-form-title span:nth-child(2) {
		margin: 21px 0 0;
	}
	.con-industries-wr h3 span:nth-child(2), .company-branding-wr h3 span:nth-child(2), .con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 40px;
		line-height: 53px;
	}
	.get-started-slide .swiper-pagination {
		bottom: 25px;
	}
	.project-widget-area{
		display: none;
	}
	.project-widget-area.mobile{
		display: block;
	}
	.project-block h3 {
		font-size: 20px;
		line-height: 27px;
	}
	.testimonial-slider .swiper-button-prev {
		margin-left: 63%;
	}
	.life-at-conative-slider .swiper-button-prev, .life-at-conative-slider .swiper-button-next {
		width: 55px;
		height: 55px;
	}
	.life-at-conative-wr {
		padding: 45px 0 130px;
	}
	.check-our-culture-btn {
		margin-top: 43px;
	}
	.get-started-blk {
		width: 100%;
	}
	.con-journey-slide {
		min-height: 542px;
	}
	.con-html-css-wr {
		width: 94%;
		margin: 0;
	}
	.con-full-wr.con-head-blk.con-jquery-wr {
		margin: 0 auto 0px;
	}
	.con-anim-left-right-wr {
		padding-top: 25px;
	}
	figure.con-angular-img {
		margin: -160px 0px 0px 47%;
	}
	.con-larvel-wr {
		margin: 39px auto 79px;
	}
	.con-full-stack-wr .con-full-wr, .con-left-right-wr.con-php-wr {
		margin-top: 20px;
	}
	.react-lib-wr.image-content-wr .icon-content-blk, .react-lib-wr.image-content-wr .icon-content-blk:nth-last-child(1) {
	    width: 100%;
		min-height: auto;
	    margin: 0px 0px 20px 0px;
	}
	.react-lib-wr.image-content-wr .content-blk{text-align: center;}
	.react-lib-wr.image-content-wr .icon-content-blk:before {
	    height: 1px;
	    right: 0;
	    width: 100%;
	    top: auto;
	    bottom: -14px;
	}
	.circle-fill-btn:before{
		transform: translateY(-50%) scale(1);
	}
	.circle-fill-btn:hover img {
		transform: translateX(23px);
	}
	.enquiry-form-content {
		height: 700px;
		overflow-y: scroll;
	}
	.sitemap-main-wrapper {
		padding: 78px 0px 145px;
	}
	.build-career-popup-wrapper {
		height: 548px;
		overflow-y: scroll;
	}
	.half-form-field {
		width: 100%;
	}
	.career_apply_now .form-radio-input{
		margin-top: 10px;
	}
	.career_apply_now .form-radio-input:first-child{margin-top: 0;}
	.build-career-popup-wrapper .form-top-heading figure {
		width: 80px;
	}
	.header-contact-information .contact-details-block{
		width: 100%;
		margin-left: 0;
	}
	.header-contact-information .contact-details-block.mail_us{
		margin-top: 30px;
	}
	.image-loop{
		height:58px;
	}
	.diwali-decor img {
		top: -50px;
	}
	.diwali-decor-1b img , .diwali-decor-2b img {
		top: -280px;
	}
	.diwali-decor-1a img ,.diwali-decor-2a img {
		top: -230px;
	}
	.con-about-img img{
		margin: 0px auto -90px;
	}
	.map-tabing:hover .location-bubble {
		top: -194px;
		opacity: 1;
	}
	.map-dots li:first-child:hover .location-bubble {
		top: -135px;
	}
	.page-banner-wr {
		padding: 40px 0 75px;
	}.con-react-wr .con-overlay-title {
		padding: 23px 30px 6px 47px;
	}.con-web-develop-design-wr .con-code-wr .con-page-content>p{
		padding-top: 80px;
	} .con-testimonial-thumbnail figcaption {
		bottom:0;
	}.con-tab-list-wr.for-res{
		margin-bottom: -9px;
	}
	.con-jquery-wr h3 {
		background-position: 126px 20%;
	}
	.industries-box img {
		margin-top: 8%;
	}.react-responsive-modal-modal {
		width: 96%;
		overflow-x: hidden;
	}	.loader-hanging-img {
		left: 60px;
		right: auto;
	}
	.loader-hanging-img.twice {
		left: auto;
		right: 66px;
	}.loader-counter-container{
		width: 54%;
	} .marquee-container h3 span.bold {
		display: block;
	}.animate-char-wr {
		bottom: 23px
	}.banner-image-blk.right.aniversary{
		padding-top: 3%;
	}.loader-dot::before {
		transform: translate(61px,-61px);
	}.animate-char-wr {
		bottom: 46px;
		text-align: center;
		left: 0;
		right: 0;
		margin: 0px auto;
	}.animate-character {
		font-size: 36px;
		line-height: 37px;
	}.banner-image-blk.right.aniversary {
		width: 100%;
	}.marquee-container h3 .btn {
		right: 0;
		left: 0;
	}.loader-video-block {
		top: 50%;
	}
	}
/*650*/
@media only screen and (max-width: 649px) {
	@keyframes scrollAnimation {
		0% {
		  left: -350px;
		  transform: translateX(0);
		}
		50% {
		  transform: translateX(160vw); 
		}51%{
		  transform: translateX(160vw) scaleX(-1); 
		}
		100% {
		  left: -350px;
		  transform: translateX(0)  scaleX(-1); 
		}
	  }
	.con-testimonial-thumbnail figcaption {
		bottom:0;
	}
    /* Home */
	.con-tab-list-wr {
		margin-bottom: 0;
	}
    h1 span {
        font-size: 39px;
        line-height: 50px;
    }
    h1 .large-head, .testimonial-head h3 {
        font-size: 82px;
        line-height: 132px;
    }
    .dynamic-txts li span {
        font-size: 69px;
        line-height: 103px;
    }
    .dynamic-txts {
        height: 81px;
    }
    p {
        font-size: 18px;
        line-height: 35px;
    }
    
    .get-started-head {
        padding: 36px 0px;
    }
    .get-started-description p {
        margin: 22px 0px 21px;
    }
    .get-started-content {
        padding: 22px 10px 38px;
    }
    .get-started-content h4, .get-started-content ul.dynamic-txts {
        font-size: 38px;
        line-height: 55px;
    }
    .get-started-content h4{
        margin-top: -39px;
    }
    .common-heading span:nth-child(2) {
        font-size: 36px;
        line-height: 40px;
        padding: 0;
    }
    .our-expertise-wr {
        padding: 75px 0 71px 0;
    }
    .common-heading span:first-child {
        font-size: 18px;
        line-height: 22px;
    }
    .project-block h3 {
        font-size: 20px;
        line-height: 26px;
        margin: 0;
    }
    .testimonial-review-blk p, .client-say-content p {
        font-size: 18px;
        line-height: 36px;
    }
    .round-bg-btn:before {
        height: 44px;
        left: 0;
        top: -7px;
        width: 44px;
    }
    .certification-wr {
		padding: 59px 0 136px 0;
		background-size: 83%;
	}
    .certification-wr .bx-viewport {
        padding: 28px 28px;
    }
	.testimonial-img-blk {
		bottom: -72px;
		right: -186px;
	}
	.testimonial-head {
		left: 0;
	}
	h1 .large-head, .testimonial-head h3 {
		font-size: 74px;
		line-height: 124px;
	}
	.lets-talk-blk {
		width: 94%;
		padding: 29px 16px 32px;
		text-align: center;
	}
	.lets-talk-description p {
		font-size: 23px;
		line-height: 31px;
	}
	.footer-call-email a {
		font-size: 16px;
		line-height: 25px;
	}
	.footer-call-email {
		margin-top: 20px;
	}
	.footer-social-icon a {
		width: 58px;
		height: 58px;
		line-height: 58px;
	}
	.office-address a {
		font-size: 15px;
		line-height: 32px;
		margin-top: 12px;
	}
    /* Life At Conative */
    .con-head-blk p {
        font-size: 16px;
        line-height: 28px;
    }
    .con-head-blk h2 {
        font-size: 25px;
        line-height: 35px;
    }
    .con-life-description-blk p {
        line-height: 32px;
    }
    .con-alternate-blk {
        margin-top: 20px;
    }
    .back-to-top {
        left: -2%;
    }
    .certification-wr .bx-pager.bx-default-pager {
        margin-top: 8px;
    }
    /*About Us*/
	.con-contact-card h3 {
	    font-size: 39px;
	    line-height: 45px;
	}
	.con-overlay-title h2 span:nth-child(2) {
    	font-size: 38px;
    	line-height: 43px;
	}
	.con-vison-content h3 span:nth-child(2), .con-vison-content h3 {
    	font-size: 24px;
	    line-height: 39px;
	}
	.con-vison-wr {
    	padding: 55px 0px 49px;
	}
	.ring-wr{display: none;}
	.con-contact-card.con-card {
	    padding: 44px 21px 91px;
	}
	.con-inquiry-wr {
    	margin: 62px 0px;
	}
	/*About Us*/
	section.map-image-wr .center-wrapper {
    	width: 100%;
	}
	.map-dots li:first-child .location-bubble {
		top: -156px;
		left: -41px;
	}
	.map-image-wr figure.map-image-blk img {
	    object-fit: cover;
	    height: 553px;
	    object-position: center;
	}
	.contact-page-form-wr {
    	margin-top: 60px;
	}
	.digital-section-wr {
		margin-top: 74px;
		background-size: 62%;
	}
	/*Our Team*/
	.con-team-contact-left .common-heading h3 span:nth-child(2), .con-team-contact-left .common-heading h3 span:nth-child(2) span {
	    font-size: 31px;
	    line-height: 40px;
	}
	.team-icon-title-wr .con-icon-title-blk {
    	width: 50%;
	}
	.team-icon-title-wr .con-icon-title-blk:nth-last-child(2){
		border-bottom: 0;
	}
	.team-icon-title-wr .con-icon-title-blk:nth-child(2n){
		border-right: 0;
	}
	.team-icon-title-wr .con-icon-title-blk:nth-child(3n){
		border-right: 1px solid rgba(0, 0, 0, 0.1);
	}
	/*Testimonial*/
	.view-more-btn {
		margin: 55px 0px 133px;	
	}
	.con-head-blk p {
	    margin-top: 10px;
	    line-height: 34px;
	}
	/* website single */
	.con-sidebar-wr ul li {
		margin-top: 15px;
		padding-bottom: 15px;
	}
	.post-list-blk .post-list-head h4 {
		font-size: 25px;
		line-height: 26px;
	}
	/* logo design */
	.subcat-col {
		width: 45.1%;
	}
	.subcat-inner-wr {
		margin-top: 39px;
	}
	.con-head-blk p {
	    margin-top: 10px;
	    line-height: 34px;
	}
	.con-html-css-wr h4 {
	    font-size: 20px;
	    line-height: 30px;
	}
	.con-right-wr.right {
		margin-top: 34px;
		position: relative;
		right: 0;
		width: 100%;
		bottom: 0;
	}
	.con-flutter-app-wr .con-about-img {
		width: 100%;
	}
	.con-backend-wr .con-right-wr {
    	padding: 27px 0px 9px;
	}
	.con-motion-list-wr h5 {
	    font-size: 26px;
	    line-height: 36px;
	    margin-bottom: 18px;
	}
	.mobile-header-list li, .con-angular-list ul li {
	    font-size: 16px;
	    line-height: 24px;
	    padding: 10px 0px 10px 42px;
	}
	.mobile-header-list, .con-angular-list {
    	padding: 34px 31px 38px 32px;
	}
	.con-motion-list-wr {
		border-radius: 20px;
    	padding: 31px 35px 31px 35px;
	}
	section.con-web-app-wr .con-overlay-title {
    	padding: 26px 0px 6px;
		margin: 90px auto 0;
	}
	.con-queries-form .enquiry-form-requirements {
    	width: 100%;
	}
	.con-queries-form .form-field {
	    width: 100%;
	    margin-right: 0;
	}
	/*ecommerce*/
	.con-ecom-list-wr ul li {
    	width: 100%;
	}
	.con-tab-list-wr li {
    	width: 100%;	
	}
	.con-react-wr .con-overlay-title {
    	padding-bottom: 0;
	}
	.con-mangneto-wr .con-img-title-blk {
    	margin-bottom: 32px;
	}
	.con-woocom-wr {
    	margin-top: 26px;
	}
	.con-big-com-wr .con-big-com-blk.con-head-blk.con-page-content {
	    padding: 0px 10px 28px;
	}
	.con-work-service-wr {
    	padding: 40px 0px 128px;
	}
	.other-services-list li a span br{display: none;}
	.con-ecom-list-wr ul li {
	    font-size: 17px;
	    line-height: 21px;
	}
	.react-lib-wr.image-content-wr .icon-content-blk:nth-last-child(1) {
    	width: 100%;
    	margin-bottom: 0;
	}
	.content-blk h5 {
	    font-size: 16px;
	    line-height: 24px;
	}
	.con-flutter-app-wr .con-overlay-title {
    	padding: 20px 0px;
	}
	.con-flutter-bottom-app-wr .con-tab-list-wr li {
	    font-size: 17px;
	    line-height: 30px;
	    width: 100%;
	    padding: 10px 0px 10px 38px;
	}
	.con-woocom-wr .con-right-wr .con-page-content p {
		line-height: 30px;
	}
	.con-mangneto-wr .con-overlay-title {
		width: 34%;
		padding-left: 3.2%;
	}
	/*digital solutions*/
	.services-page-content-blk .con-about-img, .services-page-content-blk .con-head-blk {
    	width: 100%;
	}
	.image-content-wr {
    	width: 84%;	
    	text-align: center;
	}
	.icon-blk.left img{
		margin: 0px auto;
	}
	.content-blk{
		text-align: center;
	}
	section.con-digital-services-wr {
    	padding: 60px 0px 70px;
	}
	.social-media-marketing-content ul li {
    	margin: 19px 0px 0px 0px;
    	width: 100%;
	}
	.social-media-marketing-content ul li:first-child{
		margin-top: 0;
	}
	.social-media-marketing-content ul {
		margin-bottom: 20px;
	}
	.content-blk:before {
	    width: 47px;
	    height: 47px;
	    top: -8px;
	    right: 0;
	    left: 0;
	    margin: 0px auto;
	}
	.con-tab-list-wr {
    	margin-top: 18px;
	}
	.con-content-manage-wr .con-overlay-title {
	    width: 100%;
	    margin: 0px auto;
	    padding: 30px 5px 24px;
	}
	/*designing*/
	.con-left-right-wr.con-ux-wr {
    	margin-top: 71px;
	}
	.con-graphic-design-wr .con-head-blk {
    	padding: 15px 0px;
	}
	/*animations*/
	.con-animations-wr .con-blockquote-blk {
	    width: 100%;
	    margin-bottom: 9px;
	}
	.gifs-list-wr .con-tab-list-wr li {
	    width: 100%;
	}
	.con-anim-left-wr {
    	width: 85%;
    }
    figure.con-motion-img img {
	    object-fit: cover;
	    height: 184px;
	    object-position: -49px 0px;
	}
	.con-motion-graphic-wr {
    	padding-top: 65px;
	}
	.con-video-wr .con-overlay-title {
		padding: 34px 28px 34px 0px;
	}
	/* industries we serve */
	.con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 30px;
		line-height: 42px;
	}
	.company-branding-wr .testimonial-head h3 {
		font-size: 77px;
		line-height: 108px;
	}
	.con-icon-title-blk {
		width: 100%;
		margin: 0px 0px 30px;
		padding: 20px 0px;
	}
	.triangle:after, .triangle:before {
		width: 230px;
		height: 230px;
		background-size: 100%;
		left: 0;
		right: 0;
		margin: 0px auto;
	}
	.con-industries-wr h3 span:nth-child(2), .company-branding-wr h3 span:nth-child(2), .con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 35px;
		line-height: 49px;
	}
	.con-technologies-wr .design-sol-tabber-link li {
		width: 73%;
		margin: 0px 0px 18px;
	}
	.design-sol-tabber-link {
		margin-bottom: 30px;
	}
	.con-technologies-wr .con-icon-title-blk {
		width: 100%;
		margin: 0px 0px 26px 0%;
	}
	.inquiry-form-title h3 span:nth-child(2) {
		font-size: 27px;
		line-height: 37px;
	}
	.background-text-wr h3 {
		font-size: 81px;
		line-height: 84px;
	}
	.con-technologies-wr {
		padding-bottom: 116px;
	}
	.industries-slider {
		width: 92%;
	}
	.get-started-slide .swiper-pagination {
		bottom: 10px;
	}
	/* Menu */
	.header-bottom-info-wrap a {
		width: 79%;
	}
	.header-contact-information .contact-details-block {
		margin-left: 0;
		margin-top: 15px;
	}
	.header-bottom-wrapper .center-wrapper:before{
		height: 7px;
		left: 0;
	}
	.header-bottom-wrapper .center-wrapper {
		padding-bottom: 26px;
	}
	.header-logo-block {
		width: 69%;
	}
	.menu-close-icon {
		margin: 11px 0px;
	}
	.our-project-wr .trigger-hover:nth-child(2) {
		margin-top: 0;
	}
	.life-at-conative-slider .swiper-button-prev {
		left: -10px;
	}
	.life-at-conative-slider .swiper-button-next {
		right: -10px;
	}
	.life-at-conative-slider {
		padding: 0 20px;
	}
	.life-at-conative-content {
		width: 100%;
		margin: 30px auto 0px;
	}
	.submenu li {
		width: 50%;
	}
	.submenu li a > span.submenu-text-wr {
		width: 70%;
		margin-left: 10px;
	}
	.submenu li a > span.submenu-text-wr span.header-submenu-content {
		transform: translateY(0px);
		display: block;
		opacity: 1;
		margin-top: 5px;
		visibility: visible;
	}
	.submenu li a > span.submenu-text-wr span.submenu-text {
		transform: translateY(0px);
	}
	.services-page-content-blk .con-about-img:after{
		display: none;
	}
	.testimonial-custom-modal div {
		width: 450px !important;
		height: 300px !important;
	}
	.upload-file-value{
		padding: 18px 45px;
	}
	.build-career-popup-wrapper .form-top-heading figure {
		width: 58px;
	}
	.header-contact-content {
		width: 53%;
		margin: 0px 0px;
	}
	.menu-blk , .offilne-menu-blk{
		margin-left: 10px;
		width: 20%;
	}

	.header-contact-information .contact-social-icon-list li a {
		width: 55px;
		height: 55px;
	}
	.circle {
		width: 320px;
		height: 320px;
	}
	.circle h1 {
		margin: 91px 0px 151px;
		font-size: 48px;
		line-height: 64px;
	}
	.diwali-decor img {
		top: -80px;
	}
	.diwali-decor-1b img , .diwali-decor-2b img {
		top: -280px;
	}
	.diwali-decor-1a img ,.diwali-decor-2a img {
		top: -230px;
	}
	.industries-box img {
		margin-top: 8%;
	}
	.industries-box-heading {
		margin-top: -41px;
	}
	.react-responsive-modal-modal {
		width: auto;
		overflow-x: auto;
	}	.loader-event-img {
		width: 300px;
	}.loader-event-img.twice {
		width: 310px;
		left: 51%;
		bottom: 0;
	}
	#loader-content-wr h1 {
		font-size: 52px;
		line-height: 46px;
	}.numbers div {
		font-size: 71px;
		line-height: 110px;
	}.loader-counter-container{
		width: 61%;
	}.numbers div {
		font-size: 71px;
		line-height: 110px;
	}	.counter-sub {
		font-size: 23px;
		line-height: 27px;
	}.loader-counter-container .counter-suffix {
		font-size: 25px;
	}.numbers div, .counter-aniv {
		height: 83px;
	}
	.aniversary-sparkle-collection-wr.exclaim {
		bottom: 6%;
		width: 13%;
	}.loader-dot::before {
		transform: translate(52px,-51px);
	}.animate-char-wr {
		bottom: 46px;
		text-align: center;
		left: 0;
		right: 0;
		margin: 0px auto;
	}.animate-character {
		font-size: 36px;
		line-height: 37px;
	}.text-image {
    font-size: 98px;
	}
}
/*568*/
@media only screen and (max-width: 567px){
	.common-heading.anim{
		padding-bottom: 41px;
	}
	.industries-box-heading {
		margin-top: 19px;
	}

	.industries-box img {
		margin-top: 11%;
		margin-bottom: -17%;
	}
		/*About Us*/
		.image-loop{
		height: auto;
		}
	.con-overlay-title h2 span:nth-child(2) {
	    font-size: 34px;
	    line-height: 40px;
	}
	.con-about-wr .con-overlay-title {
		width: 100%;
		padding: 18px 0px;
		margin: 0;
	}
	.con-inquiry-card {
    	width: 78%;
	}
	.contact-form-left-heading a {
		font-size: 23px;
		line-height: 28px;
	}
	ul.footer-menu li {
		padding-left: 20px;
		margin-top: 10px;
	}
	.footer-content-block h4 {
		margin-bottom: 18px;
	}
	.common-heading span:nth-child(2) {
		font-size: 33px;
		line-height: 37px;
		padding: 0;
	}
	.common-heading span:first-child {
		padding: 0;
	}
	.common-heading span:first-child::after {
		height: 7px;
		bottom: 0;
	}
	.our-project-heading.left h3 span:nth-child(2) {
		font-size: 36px;
		line-height: 43px;
	}
	/*Contact Us*/
	.contact-page-form-content .form-checkbox-input {
	    margin: 0px 0 13px 0px;
	    width: 100%;
	}
	.contact-page-form-wr {
    	margin-top: 46px;
	}
	.conatct-form-detail {
		padding: 41px 12px;
		margin-top: 20px;
	}
	.map-dots li:first-child {
	    top: 60%;
	    left: 10%;
	}
	.map-image-wr figure.map-image-blk img {
    	height: 443px;
	}
	.grow-with-us p {
		font-size: 17px;
		line-height: 35px;
	}
	/*Our Team*/
	.con-page-content p {
    	margin-top: 10px;
	}
	.con-team-inner::before {
    	height: 98px;
	}
	.con-page-content p {
	    font-size: 17px;
	    line-height: 37px;
	}
	.con-team-contact-left .common-heading h3 span:nth-child(2), .con-team-contact-left .common-heading h3 span:nth-child(2) span {
	    font-size: 28px;
	    line-height: 36px;
	}
	.con-team-contact-right figure::before {
	    left: 0;
	    width: 135px;
	    height: 130px;
	    right: 0;
	    margin: 0px auto;	
	}
	.con-team-contact-right {
	    width: 100%;
	    margin-top: 127px;
	}
	.con-team-contact-left .common-heading h3 span:nth-child(2), .con-team-contact-left .common-heading h3 span:nth-child(2) span {
	    font-size: 23px;
	    line-height: 31px;
	}
	.common-round-btn {
	    font-size: 14px;
	    line-height: 19px;
	}
	/*Testimonial*/
	.con-head-blk p, .cits-inner-head-content p {
	    font-size: 17px;
	    line-height: 34px;
	}
	.page-banner-heading, .con-head-blk h2, .con-head-blk h3{
	    font-size: 34px;
	    line-height: 42px;
	}
	.con-testimonial-wr .con-head-blk h2 {
    	margin-bottom: 10px;
	}
	.testimonial-post-blk {
	    width: 100%;
	    margin: 40px auto 0px;
	}
	/* .testimonial-post-blk {
    	padding: 35px 30px 40px;
	} */
	.con-head-blk p {
	    margin-top: 12px;
	    font-size: 16px;
	    line-height: 30px;	
	}
	/*Enquiry Form*/
	.form-field input, .form-field textarea, .form-field select {
	    font-size: 15px;
	    line-height: 16px;
	}
	.form-submit-btn input {
    	padding: 18px 59px 18px 34px;
	}
	.enquiry-form-content figure {
	    width: 52px;
	}
	.form-heading h4 {
		font-size: 26px;
		line-height: 33px;
		margin: 0;
	}
	.enquiry-form-content {
		padding: 20px 0 29px;
		height: 489px;
		overflow-y: scroll;
	}
	.form-checkbox-input {
    	margin: 0px 3% 15px 0px;
	}
	.enquiry-form-con h4 {
	    font-size: 17px;
	}
	.form-cross-btn p {
	    font-size: 21px;
	    line-height: 26px;
	}
	.form-cross-btn {
    	top: 0.9%;
	}
	/* Career single */
	.career-business-development-blk h2 {
		font-size: 28px;
		line-height: 37px;
	}
	.careers-content-inner h3 {
		margin-top: 20px;
	}
	.career-business-development-blk p, .careers-content-inner p {
		font-size: 16px;
		line-height: 24px;
		margin-top: 17px;
	}
	.conative-common-list ul li {
		font-size: 16px;
		line-height: 32px;
		padding: 0px 8px 0px 30px;
	}
	.career-apply-form-heading h3 {
		font-size: 29px;
		line-height: 31px;
	}
	.career-apply-form-heading {
		margin-bottom: 16px;
	}
	/* 404 */
	.error-heading h1 {
		font-size: 34px;
		line-height: 50px;
	}
	.error404-content figure {
		width: 47%;
		margin: 0px auto 32px;
	}
	section.error404-content .intro-text {
		margin: 15px auto;
	}
	.error-page-wr {
		padding: 78px 0px 138px;
	}
	/* Portfolio */
	.post-grid-blk {
		width: 84%;
	}
	.post-list-head .show-more {
		font-size: 14px;
		line-height: 17px;
		padding: 11px 13px;
	}
	/* logo design */
	.subcat-col {
		width: 88%;
		margin: 0px auto 40px;
	}
	.subcat-inner-wr , .subcat-inner-wr:nth-child(2n){
		text-align: center;
	}
	.grpahic-sub-cat-wr {
		margin: 13px 0px 0px;
	}
	.post-tabs-wr {
		margin: 43px auto 75px;
	}
	/* flyer design */
	.four-col-wr .subcat-col:first-child, .four-col-wr .subcat-col:nth-child(4) {
		width: 100%;
	}
	.four-col-wr .subcat-col:nth-child(2), .four-col-wr .subcat-col:nth-child(3) {
		width: 100%;
		margin-top: 30px;
	}
	/* brochure */
	.two-col-wr .subcat-col:first-child, .two-col-wr .subcat-col:nth-child(2) {
		width: 100%;
	}
	/*services*/
	.banner-heading, .con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
	    font-size: 23px;
	    line-height: 32px;
	}
	.con-page-content .con-blockquote-blk p {
		font-size: 23px;
		line-height: 41px;
	}
	.con-page-content .con-blockquote-blk {
    	margin: 22px 0px;
    }
    /*Services*/
    .con-space-wr {
    	padding: 48px 0px 52px;
	}
	.grow-with-us p, .con-html-css-wr .con-blockquote-blk blockquote p {
	    font-size: 16px;
	    line-height: 34px;
	}
	.con-html-css-wr h4 {
		font-size: 18px;
		line-height: 28px;
	}
	.con-backend-wr .con-right-wr {
		margin-top: 10px;
		margin-bottom: 0;
	}
	figure.con-angular-img {
    	margin: 19px auto 0px;
    }
    .con-tab-list-wr li {
		font-size: 16px;
		line-height: 29px;
		margin-bottom: 15px;
	}	
	.con-full-stack-wr .con-full-wr, .con-left-right-wr.con-php-wr {
    	margin-top: 43px;
	}
	.con-page-content .con-blockquote-blk p {
	    font-size: 18px;
	    line-height: 30px;
	}
	.con-why-code-wr .con-left-wr blockquote {
		padding-left: 19px;
		margin-bottom: 24px;
	}
	.con-ecom-list-wr ul li {
	    font-size: 16px;
	    line-height: 17px;
	}
	.con-ecom-list-wr {
    	padding: 22px 14px 23px 15px;
	}
	.con-shopify-wr {
    	margin-top: 49px;
	}
	.other-services-list li {
	    margin: 0px 0px 50px 0px;
	    width: 100%;
	    text-align: left;
	}
	.other-services-list li a{display: block;}
	.other-services-list li a span {
    	width: 88%;
	}
	.other-services-list li a span br{
		display: none;
	}
	.con-motion-list-wr {
	    padding: 31px 25px 31px 24px;
	    border-radius: 19px;
	}
	.con-digital-services-wr .con-anim-right-wr .con-motion-list-wr h5 {
	    font-size: 21px;
	    line-height: 28px;
	}
	section.con-digital-services-wr .con-left-right-wr {
    	margin-top: 20px;
	}
	.con-digital-services-wr .con-anim-left-wr .con-head-blk, section.con-digital-services-wr .con-right-wr.right .con-head-blk, .con-web-develop-design-wr .con-right-wr .con-head-blk {
    	padding-bottom: 8px;
	}
	.social-media-marketing-content, .con-flutter-bottom-app-wr .con-tab-list-wr {
    	padding: 37px 30px 28px 27px;
	}
	/*desgning*/
	.con-customers-wr.con-tab-list-wr h4 {
	    font-size: 23px;
	    line-height: 33px;
	}
	.con-services-left .con-head-blk, .con-cross-outer-wr .con-head-blk, .con-htm-css-wr .con-right-wr .con-head-blk, .con-web-develop-design-wr .con-right-wr .con-head-blk, .con-web-develop-design-wr .con-anim-left-right-wr .con-anim-left-wr .con-head-blk, .con-php-wr .con-head-blk, .con-larvel-wr .con-head-blk, .con-full-width-wr.con-ue-wr .con-head-blk, .con-left-right-wr.con-ux-wr .con-right-wr .con-head-blk, .con-logo-wr .con-left-right-wr .con-right-wr .con-head-blk, .con-logo-wr .con-full-width-wr .con-head-blk, .con-product-content-wr .con-head-blk, .con-illustration-wr .con-head-blk, .con-ecom-services-inner-wr .con-head-blk, .con-woocom-wr .con-right-wr .con-head-blk, .con-shopify-wr .con-head-blk {
    	padding-bottom: 14px;
	}
	section.con-ui-design-wr.con-space-wr:before {
	    height: 100%;
	    width: 100%;
	}
	.con-left-right-wr.con-ux-wr {
    	margin-top: 14px;
	}	
	.con-head-blk h4 {
		font-size: 22px;
		line-height: 28px;
	}	
	.con-logo-wr .con-head-blk h3 {
    	margin-bottom: 23px;
	}
	section.con-graphic-design-wr {
    	padding: 40px 0px 48px;
	}
	.con-illustration-wr .con-motion-list-wr h5 {
    	font-size: 18px;
	}
	.con-logo-wr .con-full-width-wr {
	    width: 100%;
	    margin: 45px auto 0px;
	}
	/*animations*/
	.gifs-list-wr .con-blockquote-blk blockquote p {
	    font-size: 16px;
	    line-height: 33px;
	}
	.gifs-list-wr .con-blockquote-blk{
		margin-top: 20px;
	}
	.gifs-list-wr .con-blockquote-blk blockquote {
    	padding: 19px 20px 17px 17px;
	}
	.con-animations-wr .con-blockquote-blk p {
	    font-size: 18px;
	    line-height: 33px;
	}
	.con-head-wr h4 {
    	font-size: 24px;
	    line-height: 35px;
	}
	/* industries we serve */
	.con-industries-wr h3 span:nth-child(2), .company-branding-wr h3 span:nth-child(2), .con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 31px;
		line-height: 40px;
	}
	.enquiry-form-name, .enquiry-form-email {
		width: 100%;
	}
	.industries-left-blk .form-field, .industries-left-blk .enquiry-form-start-project {
		margin-bottom: 20px;
	}
	.industries-form-blk {
		margin-bottom: 45px;
		padding: 26px 19px;
		border-radius: 13px;
	}
	.background-text-wr h3 {
		font-size: 57px;
		line-height: 77px;
	}
	.form-upload-field:before, .form-upload-field:after {
		width: 49px;
		height: 49px;
	}
	.industries-form-blk .form-upload-field:before, .industries-form-blk .form-upload-field:after {
		left: auto;
		top: auto;
		transform: unset;
		right: 0;
		left: 0;
		margin: 0px auto;
		bottom: -28px;
	}
	.industries-form-blk .form-submit-btn input {
		width: 100%;
	}
	.con-jquery-wr h3 {
		background-size: 42px;
		background-position: 22% 50%;
	}
	.experience-list ul li:first-child::before,.experience-list ul li{display: block;}
	.experience-list{
		text-align: left;
	}
	.get-quote span:nth-child(2n){
		display:none;
	}
	.get-quote {
		right: 112px;
	}
	.digital-image-blk {
		width: 450px;
	}
	.digital-slider-area {
		width: 91%;
		top: 18px;
		padding: 0px 0px;
		left: 7px;
	}
	.digital-slider .swiper-button-prev {
		left: 0;
	}
	.digital-slider .swiper-button-next {
		right: 0;
	}
	.con-skill-blk{
		width: 46.5%;
	}
	.con-skill-blk:nth-child(2n+1){
		margin-left: 0;
	}
	.career-page-heading h3 {
		font-size: 30px;
		line-height: 43px;
	}
	.build-career-wr {
		padding: 56px 0 9px;
	}
	.build-career-all-blocks {
		padding-top: 30px;
	}
	.our-client-wr {
		padding: 50px 0px 59px;
	}
	.testimonial-area {
		margin-top: 69px;
	}
	.certification-slider-inner .swiper {
		padding: 0px 31px 91px;
		margin: 0px -20px;
	}	
	.con-journey-wr {
		padding: 41px 0px 22px;
	}
	.con-journey-slide {
		min-height: 483px;
	}
	.header-contact-information .contact-details-block{
		width: 100%;
	}
	.perks-benefit-box {
		padding: 40px 14px 0 14px;
		min-height: 210px;
	}
	.team-icon-title-wr .con-title-blk h4 {
		font-size: 18px;
		line-height: 27px;
	}
	.post-head .testimonial-slide-title h4 {
		font-size: 23px;
		line-height: 27px;
		text-align: left;
	}
	.con-node-wr .con-left-wr:before {
		width: 74px;
		height: 74px;
		top: -27px;
		left: 16px;
		background-size: 66%;
	}
	.con-queries-wr .con-head-blk h2 {
		font-size: 30px;
		line-height: 42px;
	}
	
	section.con-flutter-app-wr.con-space-wr {
		padding: 20px 0px 0px;
	}
	.con-space-wr.con-eccom-wr {
		padding-bottom: 30px;
	}
	.con-about-img:before, .con-image-blk:before, .con-cross-outer-wr:before, .con-full-stack-wr .con-full-image-blk::before, .con-logo-wr .con-left-right-wr .con-left-wr:before, .product-braning-inner-wr .con-product-content-wr::before, .con-print_material .con-about-img:before, .con-woocom-wr .con-left-wr:before{
		display: none;
	}
	.con-logo-process-wr .con-page-content {
		padding: 36px 26px 36px 36px;
	}
	.con-other-service-wr.con-animation-other-services {
		padding: 0px 0px 106px;
	}
	.testimonial-widget-area {
		margin-top: -282px;
	}
	.testimonial-custom-modal div {
		width: 360px !important;
		height: 235px !important;
	}		
	.testimonial-custom-modal{
		padding: 0.5rem !important;
	}
	.upload-file-value{
		padding: 18px 24px;
	}
	.sitemap-links-wrapper ul li a {
		font-size: 16px;
		line-height: 22px;
	}
	.sitemap-links-wrapper ul li::after{
		top:17px;
	}
	.sitemap-inner-wrapper {
		padding: 28px 17px;
	}
	.sitemap-links-wrapper ul li span {
		font-size: 12px;
		line-height: 15px;
		padding-left: 27px;
	}
	.sitemap-links-wrapper ul li span:before {
		width: 30px;
		height: 15px;
		background-size: 20px;
		top: 2px;
	}
	/* diwali */
	.circle h1 {
		margin: 82px 0px 151px;
		font-size: 39px;
		line-height: 54px;
	}
	.circle {
		width: 270px;
		height: 270px;
	}
	.diwali-decor img {
		top: -170px;
	}
	.diwali-decor-1b img , .diwali-decor-2b img {
		top: -280px;
	}
	.diwali-decor-1a img ,.diwali-decor-2a img {
		top: -230px;
	}
	.con-alternate-blk:nth-child(2n) .con-right-blk, .con-alternate-blk:nth-child(odd) .con-right-blk {
		margin: 16px 0 0;
	}
	.con-print_material .con-overlay-title {
		padding: 18px 62px 26px 39px;
	}
	.con-left-right-wr.con-php-wr.for-dev{
		margin-top: -9px;
	}
	.career-apply-form-select, .career-select-field select {
		padding: 25px 36px 25px 7px;
	}
	.post-list-blk:nth-child(even) .post-list-head {
		left: 15px;
	}.post-list-head .show-more {
		font-size: 13px;
		line-height: 16px;
		padding: 11px 11px;
	}.react-responsive-modal-modal {
		width: auto;
		overflow-x:auto;
	}	.loader-event-img{
		display: none;
	}
	.loader-event-img.twice{
		display: block;
		left: -45px;
		width: 35%;
		
	}.loader-counter-container{
		width: 75%;
	}.numbers div {
		font-size: 58px;
		line-height: 110px;
	}.counter-sub {
		font-size: 21px;
		line-height: 25px;
	}.clinking-glass-img {
		width: 3.3%;
	}.loader-dot::before {
		transform: translate(44px,-45px);
		height: 10px;
		width: 10px;
	}.animate-character {
		font-size: 36px;
		line-height: 37px;
	}.animate-char-wr {
		bottom: 34px;
		left: 0;
	}.white-logo-img {
    width: 52%;
}.text-image {
	font-size: 92px;
}
}
/* 480 */
@media only screen and (max-width: 479px) {
	@keyframes scrollAnimation {
		0% {
		  left: -350px;
		  transform: translateX(0);
		}
		50% {
		  transform: translateX(180vw); 
		}51%{
		  transform: translateX(180vw) scaleX(-1); 
		}
		100% {
		  left: -350px;
		  transform: translateX(0)  scaleX(-1); 
		}
	  }
	.con-page-content.class p{
		margin-top: 100px;
	}
	.form-field input, .form-field textarea, .form-field select {
		padding-right: 36px;
	}
.con-journey-slide{
	min-height: 461px;
}
	/* home */
	.digital-bg-img img {
		height: 365px;
	}
	.digital-slider-area {
		width: 302px;
		top: 20px;
	}
    /* Life At Conative */
    .con-head-blk h2 {
        font-size: 29px;
        line-height: 39px;
    }
    .con-left-blk::before, .con-left-blk::after {
        position: absolute;
        width: 350px;
        height: 350px;
    }
    .con-life-heading-blk h3, .con-head-wr h4 {
        font-size: 26px;
        line-height: 32px;
    }
	.footer-our-services, .footer-our-company, .footer-industries-serve, .footer-content-block.footer-industries-serve {
		width: 100%;
	}
	.footer-social-icon a {
		width: 52px;
		height: 52px;
		line-height: 52px;
	}
	.footer-content-block.footer-logo a {
		width: 84%;
	}
	.box-shadow-box {
		display: none;
	}
	figure.testimonial-client-img {
		width: 210px;
		height: 316px;
		top: -123px;
		left: -91px;
	}
	.testimonial-img-blk {
		bottom: -71px;
		right: -112px;
	}
	.testimonial-media-blk .video-btn {
		width: 89px;
		height: 89px;
	}
	.testimonial-media-blk figure .video-btn img {
		padding: 32px 0px;
	}
	figure.testimonial-client-img{padding: 0;}
	.circle-1 {
		width: 116px;
		height: 116px;
		top: -14px;
		left: -12px;
	}
	.circle-2 {
		width: 139px;
		height: 139px;
		top: -24px;
		left: -23px;
	}
	.circle-3 {
		width: 151px;
		height: 151px;
		top: -30px;
		left: -29px;
	}
	.get-started-content h4, .get-started-content ul.dynamic-txts {
		font-size: 32px;
		line-height: 48px;
	}
	.get-started-content ul.dynamic-txts {
		line-height: 55px;
	}
	.development-block h4,  .development-block h4 a {
		font-size: 19px;
		line-height: 25px;
	}
	.counter-blk {
		width: 100%;
		border-bottom: 2px solid rgba(0,0,0,0.1);
	}
	.counter-blk:before{display:none;}
	.digital-section-wr {
		margin-top: 45px;
		background-size: 60%;
	}
	.build-career-text {
		width: 100%;
	}
	.build-career-icon {
		width: 100%;
		text-align: right;
	}
	.perks-benefit-box {
		width: 83%;
		min-height: auto;
		padding: 12px 20px;
		margin: 20px auto 0px;
		float: unset;
	}
	.perks-benefit-box:nth-child(2n + 1) {
		margin-left: auto;
	}
	.life-at-conative-content {
		position: relative;
		width: 94%;
		margin: 58px auto 0px;
	}
	.life-at-conative-wr .bx-controls-direction .bx-next {
		right: -20px;
	}
	.life-at-conative-wr .bx-controls-direction .bx-prev {
		left: -20px;
	}
	.life-at-conative-wr .bx-controls-direction .bx-prev, .life-at-conative-wr .bx-controls-direction .bx-next {
		width: 50px;
		height: 50px;
	}
	.life-at-conative-wr {
		padding: 31px 0 146px;
	}
	.cits-inner-head-content {
		width: 100%;
	}
	.perks-benefit-box-heading {
		margin-top: 12px;
		min-height: auto;
	}
    /*About Us*/
	.con-inquiry-wr .contact-form-left-heading span {
		font-size: 15px;
		line-height: 17px;
		margin-bottom: 8px;
	}
	.con-card-icon {
    	margin-right: 14px;
	}
	.con-inquiry-card {
	    width: 93%;
	    padding: 30px 29px;
		text-align:center;
	}
	/* .page-banner-heading, .con-head-blk h2, .con-head-blk h3{
	    font-size: 32px;
	    line-height: 41px;
	} */
	.con-choose-wr {
    	padding-top: 49px;
	}
	.con-card-title h3 {
	    font-size: 27px;
	    line-height: 28px;
	    margin-bottom: 8px;
	}
	.con-choose-wr .con-card-blk {
	    width: 100%;
	    padding: 30px 20px;
	}
	.con-journey-head h3 span:nth-child(2), .con-skill-title h3 span:nth-child(2), .con-team-built-head h3 span:nth-child(2) {
	    font-size: 32px;
	    line-height: 41px;
	}
	.con-page-content p {
	    font-size: 17px;
	    line-height: 34px;
	    margin-top: 15px;
	}
	.counter-value h3, .counter-value span {
		font-size: 36px;
		line-height: 44px;
		margin-top: 11px;
	}
	.con-card-title h3 {
		font-size: 24px;
		line-height: 26px;
		margin-bottom: 7px;
	}
	.con-card-title h3 {
		font-size: 24px;
		line-height: 26px;
		margin-bottom: 7px;
	}
	
	/*Contact Us*/
	.map-dots li:nth-child(2) {
	    bottom: 36%;
	    right: 32.4%;
	}
	.map-dots li:first-child {
		left: 11%;	
	}
	.map-image-wr {
	    padding: 19px 0;
	}
	.form-submit-btn input {
		padding: 19px 60px 19px 32px;
		font-size: 14px;
		line-height: 19px;
		width: 100%;
	}
	.enquiry-form-upload-field.form-field .form-upload-field input {
    	padding: 19px 13px 41px;
	}
	.form-upload-field span {
	    font-size: 15px;
	    line-height: 19px;
	}
	.contact-form-left-heading a {
	    font-size: 21px;
	    line-height: 25px;
	}
	/*Our Team*/
	.con-team-content h2 {
	    font-size: 26px;
	    line-height: 37px;
	}
	.con-team-inner {
    	padding-left: 20px;
	}
	.con-team-content.con-overlay-title {
    	padding: 26px 6px;
	}
	.team-icon-title-wr .con-title-blk h4 {
		font-size: 17px;
		line-height: 27px;
	}
	.team-icon-title-wr .con-icon-title-blk {
	    min-height: 198px;
	}
	.team-icon-title-wr .con-icon-title-blk {
    	width: 100%;
    	min-height: auto;
    	border-right: 0;
	}
	.team-icon-title-wr .con-icon-title-blk:nth-child(2n){
		border-right: 0;
	}
	.team-icon-title-wr .con-icon-title-blk:nth-last-child(2){
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}
	.team-icon-title-wr .con-icon-title-blk:nth-child(3n), .team-icon-title-wr .con-icon-title-blk:nth-child(5n) {
    	border-right: 0;
	}
	.team-icon-title-wr .con-icon-title-blk:nth-last-child(1){
		border-left: 0;
	}
	/* 404 */
	.error-heading h1 {
		font-size: 29px;
		line-height: 44px;
	}
	.header-contact-content {
		width: 52%;
	}
	/* Portfolio */
	.post-grid-blk {
		width: 100%;
	}
	/*Services*/
	.con-queries-wr {
    	padding: 33px 0px;
	}
	.con-left-right-wr.con-javascript-wr, .con-node-wr .con-left-wr {
    	margin-top: 14px;
	}
	.con-code-wr.con-full-wr .con-overlay-title {
		width: 100%;
	    margin: 0;
	    position: unset;
	}
	.con-react-head-wr h4 {
	    font-size: 19px;
	    line-height: 33px;
	}
	.con-react-features {
    	padding: 31px 25px 24px;
	}
	.con-react-list-wr.con-tab-list-wr ul li:nth-child(3n+1), .con-react-list-wr.con-tab-list-wr ul li{
		margin-left: 0;
	}
	.con-work-service-wr {
    	padding: 28px 0px 96px;
	}
	/* .con-head-blk h3, .post-list-blk .post-list-head h4 {
		font-size: 32px;
		line-height: 37px;
		padding-left: 10%;
		width: 210px;
	} */
	.con-motion-list-wr h5 {
	    font-size: 23px;
	    line-height: 34px;
	    margin-bottom: 0;
	}
	.con-tab-list-wr {
    	margin-top: 15px;	
	}
	.mobile-header-list, .con-angular-list {
    	padding: 30px 25px 26px 26px;
	}
	.con-web-app-wr .con-about-img {
    	width: 100%;
	}
	.con-full-image-blk {
	    padding: 11px 6px;
	    width: 100%;
	}
	.con-full-wr .con-head-blk.con-page-content {
	    width: 100%;
	    padding: 0;
	}
	/*ecommerce*/
	.con-woocom-wr {
    	margin-top: 16px;
	}
	.con-eccom-wr .con-overlay-title {
	    padding: 15px 0px;
	}
	.con-blockquote-blk blockquote p {
    	font-size: 19px;
	    line-height: 33px;
	}
	.con-code-wr.con-full-wr {
    	margin-top: 59px;
	}
	.con-larvel-wr {
    	margin: 60px auto 73px;
	}
	section.con-space-wr.con-react-service-wr .white-ring-circle {
	    top: 29%;
	    left: 0;
	    width: 200px;
	    height: 200px;
	}
	.con-flutter-bottom-app-wr .con-tab-list-wr li {
	    font-size: 16px;
	    line-height: 26px;
	}
	/*digital solutions*/
	section.con-digital-services-wr {
		padding: 28px 0px 40px;
	}
	.con-content-manage-wr .con-overlay-title {
    	padding: 21px 1px 16px;
		text-align: left;
	}
	.social-media-marketing-content ul li {
	    font-size: 16px;
	    line-height: 24px;
    	margin: 13px 0px 0px 0px;
	}
	.social-media-marketing-content ul li span:first-child {
	    margin-right: 14px;
	    width: 30px;
	}
	section.con-content-manage-wr .white-ring-circle{
		display: none;
	}
	/*designing*/
	.mobile-header-list, .con-angular-list {
    	padding: 30px 23px 26px 24px;
	}
	.con-illustration-wr{
		width: 100%;
		padding-top: 55px;
	}
	.con-print_material {
    	margin-top: 70px;
	}
	.con-services-content {
		padding-top: 0;
	}
	.con-customers-wr.con-tab-list-wr, .product-braning-inner-wr .con-page-content {
    	padding: 26px 11px;
	}
	.con-print-content.con-page-content.con-head-blk {
    	padding-bottom: 0;
	}
	.con-graphic-design-inner-wr .con-blockquote-blk p {
    	font-size: 19px;
    	line-height: 30px;
	}
	.con-tab-list-wr li:before {
	    top: 7px;
	}
	/* indusrties */
	.con-industries-wr h3 span:nth-child(2), .company-branding-wr h3 span:nth-child(2), .con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 27px;
		line-height: 38px;
	}
	.banner-img-overlay .common-round-btn {
		padding: 17px 36px 17px 29px;
	}
	.banner-img-overlay .video-layers {
		margin-left: 20px;
	}
	.mockup-banner-img {
		width: 88%;
		left: 0;
		margin: 0px auto;
		bottom: 33px;
	}
	.common-heading span:first-child, .con-industries-wr .con-head-blk h3 strong:first-child {
		font-size: 17px;
		line-height: 21px;
	}
	.con-industries-wr h3 span:nth-child(2), .company-branding-wr h3 span:nth-child(2), .con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 24px;
		line-height: 36px;
	}
	.inquiry-form-title h3 span:nth-child(2) {
		font-size: 25px;
		line-height: 35px;
	}
	.digital-image-blk {
		width: 350px;
	}
	.digital-bg-img img {
		height: 300px;
	}
	.digital-slider-area {
		top: 14px;
		width: 71%;
	}
	.testimonial-slider .swiper-button-prev {
		margin-left: 47%;
	}
	.testimonial-slider .swiper-button-next {
		margin: -52px 0px 0px 83%;
	}
	/* .testimonial-media-blk figure img {
		padding: 29px 0px;
	} */
	.life-at-conative-slider .swiper-button-prev, .life-at-conative-slider .swiper-button-next {
		width: 28px;
		height: 46px;
		top: 60%;
	}
	.get-started-head a {
		font-size: 30px;
		line-height: 37px;
	}
	.menu-blk , .offilne-menu-blk{
		margin-left: 0px;
	}
	.common-round-btn {
		font-size: 14px;
		line-height: 19px;
		margin: 10px 0px 0px 13px;
		padding: 16px 41px 16px 18px;
	}
	.industries-wr {
		padding: 41px 0 28px;
	}
	.testimonial-review-blk p, .client-say-content p {
		font-size: 16px;
		line-height: 34px;
	}
	.menu_tabs .accordion-head a {
		padding: 15px;
		border-radius: 10px;
	}
	.accordion-wrapper .accordion-head h3 {
		font-size: 18px;
		line-height: 20px;
		font-family: "Poppins", sans-serif;
		font-weight: 600;
	}
	.submenu li {
		width: 100%;
		margin: 23px 0px 0px;
	}
	.header-contact-information .contact-social-icon-list li a {
		width: 45px;
		height: 45px;
	}
	.accordion-content {
		margin: -13px 0px 0;
	}
	.list-banner-blk img {
		object-fit: none;
		height: 241px;
		object-position: center;
	}
	.post-list-head {
		width: 100%;
	}
	.post-list-blk:nth-child(odd) .post-list-head {
		right: 0;
		left: 0;
		margin: 0px auto;
		text-align: center;
	}
	.post-list-blk:nth-child(even) .post-list-head {
		left: 0;
		right: 0;
		margin: 0px auto;
		text-align: center;
	}
	.list-banner-blk img {
		height: 174px;
	}
	.page-medium-heading h3 {
		font-size: 17px;
		line-height: 23px;
	}
	.con-full-stack-wr .con-full-wr, .con-left-right-wr.con-php-wr {
		margin-top: 17px;
	}
	.con-code-wr.con-full-wr .con-about-img:before{display: none;}
	.con-larvel-wr {
		margin: 30px auto 62px;
	}
	.con-mangneto-wr .con-overlay-title {
		padding: 22px 0px 15px 0px;
		text-align: center;

	}
	.testimonial-media-blk .video-btn img {
		padding: 24px 0px;
	}
	.header-menu-block ul li a, .submenu li a {
		font-size: 14px;
		line-height: 18px;
	}
	.con-journey-slide {
		min-height: 500px;
	}
	.con-team-content-wr {
		padding: 57px 0px 41px;
	}
	.con-team-inner {
		padding-left: 13px;
	}
	.con-blocquote-wr ul li {
		font-size: 16px;
		line-height: 18px;
		margin-top: 16px;
	}
	figure.con-motion-img img {
		object-position: 17% 0px;
	}
	.accordion-wrapper.active .accordion-content {
		margin: 7px 0px 0px;
	}
	span.header-submenu-content p {
		line-height: 21px;
	}
	.get-started-slide .swiper-button-prev, .get-started-slide .swiper-button-next  {
		top: 42%;
	}
	.con-about-img .con-overlay-title h3 ,.con-img-title-blk .con-overlay-title h3 {
		color: #000;
	}
	.con-space-wr.design-devlop-wr, .graphic-category-wr.con-space-wr {
		padding-top: 20px;
	}
	.grpahic-sub-cat-wr {
		margin: 13px 0px -30px;
	}.perks-benefit-all-boxes {
		margin-top: 30px;
	}
	.con-jquery-wr h3 {
		background-position: 27% 50%;
	}
	.con-code-wr.con-full-wr .con-overlay-title.for-codeIgniter {
		position: absolute;
		top: 16%;
	}.con-web-develop-design-wr .con-code-wr .con-page-content>p {
		padding-top: 100px;
	}.services-page-content-blk .con-img-title-blk.anim {
		margin-bottom: -10%;
	}.con-page-content.fin{
		margin: 90px 0 50px;
	}.common-heading.anim {
		padding-bottom: 40px;
	}#loader-content-wr h1 {
		font-size: 42px;
		line-height: 46px;
	}.loader-hanging-img {
		left:0;
	}
	.loader-hanging-img.twice {
		left: auto;
		right: 0;
	}
	.loader-event-img.twice {
		width: 40%;
		bottom: 10%;
	}.loader-counter-container {
		width: 87%;
	}.numbers div {
		font-size: 48px;
		line-height: 100px;
	}.numbers div, .counter-aniv {
		height: 78px;
	}.counter-sub {
		font-size: 17px;
		line-height: 18px;
	}
	.animate-char-wr{
		bottom: 25px;
		left: 15px;
	}.loader-dot::before {
		transform: translate(37px,-36px);
		height: 8px;
		width: 8px;
	}.animate-character {
		font-size: 30px;
		line-height: 32px;
	}.text-image {
    font-size: 73px;
    line-height: 100px;
    top: -100px;
}

}
/*414*/
@media only screen and (max-width: 413px) {

	.loader-hanging-img {
		top: -20%;
	}

	.con-page-content.fin{
		margin: 0;
	}
	.common-heading.anim {
		padding-bottom: 20px;
	}
	.services-page-content-blk .con-img-title-blk.anim {
		margin-bottom: 0%;
	}
	.con-page-content.class p{
		margin-top: 50px;
	}
	.con-about-img img {
		margin: 0px auto -60px;
	}
	/* .con-skill-blk img {
		width: 58%;
	} */
    /* Home */
    .logo-block {
        width: 100%;
    }
    .header-contact-content {
        width: 100%;
        margin: 20px 0px;
        text-align: center;
    }
    h1 .large-head, .testimonial-head h3 {
        font-size: 63px;
        line-height: 100px;
    }
    .sub-head {
        margin: -40px 0px 0px 0px;
    }
    h1 span {
        font-size: 35px;
        line-height: 48px;
    }
    .dynamic-txts li span {
		font-size: 51px;
		line-height: 100px;
	}
	.dynamic-txts {
		height: 100px;
	}
    .common-round-btn:first-child{
        margin-top: 0;
    }
    .common-round-btn {
		padding: 17px 43px 17px 17px;
		font-size: 14px;
		line-height: 19px;
		margin: 12px auto 0px;
		font-weight: 600;
	}
    .start-project-build-team-btn {
        margin-top: 14px;
    }
    .get-started-content h3 span:first-child {
        font-size: 65px;
        line-height: 99px;
    }
    .get-started-content ul.dynamic-txts {
        width: 77%;
    }
    .common-heading span:nth-child(2) {
        font-size: 38px;
        line-height: 36px;
    }
    .our-expertise-wr {
        padding: 41px 0 39px 0;
    }
    
    .development-block h4 {
        font-size: 19px;
        line-height: 24px;
        min-height: auto;
    }
    .development-block:nth-child(3n + 1){
        border-left: 0;
    }
    .development-block {
        width: 100%;
        margin: 0px;
        padding: 20px 23px;
        border-left: 0;
        border-right: 0;
    }
    .our-project-wr {
        padding: 50px 0 43px;
    }
    .counter-blk {
        width: 100%;
    }
    .counter-blk:before{display: none;}
    .certification-slider-con {
        margin-top: 30px;
    }
    .lets-talk-blk {
        width: 94%;
        padding: 26px 21px 33px;
        text-align: center;
        border-radius: 27px;
    }
    .lets-talk-description p {
        font-size: 23px;
        line-height: 27px;
    }
    .footer-top {
        padding-top: 44px;
    }
    .footer-our-services, .footer-our-company, .footer-industries-serve {
        width: 100%;
    }
	.client-say-block {
		width: 100%;
	}
	.testimonial-widget-area {
		margin-top: -275px;
	}
	.testimonial-head h3 {
		font-size: 49px;
		line-height: 93px;
	}
	.testimonial-head {
		top: 40px;
	}
    /* Life At Conative */
    .con-head-blk h2 {
        font-size: 26px;
        line-height: 36px;
    }
    .con-life-description-blk p {
        line-height: 28px;
        font-size: 15px;
    }
    .con-life-heading-blk h3, .con-head-wr h4 {
        font-size: 24px;
        line-height: 32px;
    }
    .con-head-blk {
        padding-bottom: 80px;
    }
	.digital-bg-img img {
		height: 305px;
	}
	.digital-slider-area {
		width: 257px;
		top: 11px;
	}
	.digital-slider .swiper-button-prev, .digital-slider .swiper-button-next {
		width: 16px;
		height: 34px;
	}
	.digital-slider .swiper-button-prev:after, .digital-slider .swiper-button-next:after{
		background-size: 100%;
	}
    /*About Us*/
	.con-overlay-title h2 span:nth-child(2) {
	    font-size: 28px;
	    line-height: 34px;
	}
	.con-vison-content h3 span:nth-child(2), .con-vison-content h3 {
	    font-size: 19px;
	    line-height: 31px;
	}
	.con-vison-wr {
    	padding: 40px 0px 44px;
	}
	.con-contact-card.con-card {
	    padding: 30px 13px 83px;
	    border-radius: 20px;
	}
	.con-contact-card h3 {
		font-size: 27px;
		line-height: 30px;
	}
	.footer-social-icon a {
		width: 42px;
		height: 42px;
		line-height: 42px;
	}
	/*Contact Us*/
	.location-bubble {
    	width: 157px;
	}
	.map-dots li:first-child {
    	left: 13%;
	}
	.map-tabing:hover .location-bubble {
		top: -216px;
	}
	.map-dots li:first-child .location-bubble {
	    top: -189px;
	    left: -41px;
	}
	.location-bubble {
    	top: -265px;
	}
	.grow-with-us p, .con-html-css-wr .con-blockquote-blk blockquote p {
	    font-size: 16px;
	    line-height: 36px;
	}
	.contact-social-icon-list li a, .linkdin-btn {
		width: 34px;
		height: 34px;
		line-height: 34px;
	}
	.common-heading span:first-child::after, .con-industries-wr .con-head-blk h3 strong:first-child:after{display: none;}
	.contact-social-icon-list li a svg {
		width: 56%;
	}
	/*our team*/
	.con-team-content h2 {
	    font-size: 24px;
	    line-height: 33px;
	}
	.con-page-content p {
	    font-size: 15px;
	    line-height: 30px;
	    margin-top: 11px;
	}
	.con-journey-head h3 span:nth-child(2), .con-skill-title h3 span:nth-child(2), .con-team-built-head h3 span:nth-child(2) {
		font-size: 29px;
		line-height: 38px;
	}
	.con-team-contact-left .common-heading h3 span:nth-child(2), .con-team-contact-left .common-heading h3 span:nth-child(2) span {
	    font-size: 23px;
	    line-height: 28px;
	}
	/*Testimonial*/
	.con-head-blk h2, .con-head-blk h3, .post-list-blk .post-list-head h4 {
		font-size: 26px;
		line-height: 39px;
	}
	.con-head-blk {
    	padding-bottom: 0px;
	}
	.testimonial-back-blk p {
	    font-size: 16px;
	    line-height: 27px;
	}
	/* Career Single */
	.career-business-development-blk h2 {
		font-size: 24px;
		line-height: 35px;
	}
	/* 404 */
	.error-page-wr {
		padding: 53px 0px 120px;
	}
	p {
		font-size: 16px;
		line-height: 32px;
	}
	/* Portfolio */
	.post-grid-blk a {
		height: 146px;
	}
	.post-tabs-link li {
		width: 100%;
		margin-top: 10px;
		padding: 10px 0px;
	}
	.post-tabs-link li:before {
		width: 100%;
		height: 1px;
	}
	.post-tabs-link li:first-child{margin-top: 0;}
	.post-list-blk .post-list-head h4 {
		font-size: 22px;
		line-height: 20px;
	}
	.post-list-blk:nth-child(odd) .post-list-head {
		right: 0px;
	}
	.post-list-blk .post-list-head h4 {
		font-size: 21px;
		line-height: 31px;
		font-weight: normal;
		margin-bottom: 5px;
		color: #fff;
		margin-left: 28px;
	}
	.post-list-blk:nth-child(even) .post-list-head {
		left: 0;
	}
	.post-list-head .show-more {
		font-size: 11px;
		line-height: 14px;
		padding: 5px 7px;
	}
	/* services */
	.con-tab-list-wr li {
	    font-size: 15px;
	    line-height: 28px;
	}
	.con-head-blk h3{
		font-size: 25px;
    	line-height: 37px;
		text-align: left;
	}
	.services-page-content-blk .con-img-title-blk {
    	margin-bottom: 17px;
	}
	.con-blockquote-blk {
    	margin: 0px auto 23px;
	}
	.con-blockquote-blk blockquote p {
	    font-size: 18px;
	    line-height: 31px;
	}
	.other-services-list li a span {
    	width: 85%;
	}
	/*animtions*/
	.con-motion-graphic-wr {
    	padding-top: 50px;
	}
	.con-motion-list-wr h5 {
	    font-size: 20px;
	    line-height: 32px;
	}
	.con-industries-wr h3 span:nth-child(2), .company-branding-wr h3 span:nth-child(2), .con-industries-wr .con-head-blk h3 strong:nth-child(2) {
		font-size: 22px;
		line-height: 31px;
	}
	.con-industries-wr .con-icon-title-wr {
		padding-top: 29px;
	}
	.con-industries-wr .con-head-blk h4 {
		font-size: 18px;
		line-height: 22px;
	}
	.company-branding-wr .testimonial-head h3 {
		font-size: 59px;
		line-height: 100px;
	}
	.con-technologies-wr .design-sol-tabber-link li {
		width: 95%;
		margin: 0px 0px 16px;
	}
	.industries-enquiry-form-blk {
		margin: 64px auto 0px;
	}
	.banner-wr {
		padding: 0px 0px 35px;
	}
	.digital-image-blk {
		width: 297px;
	}
	.con-journey-slider-wr {
		padding: 25px 0px 6px;
	}
	.con-skill-left {
		padding: 0px 0px 36px 0px;
	}
	.life-at-conative-slider {
		padding: 0px 10px;
	}
	.build-career-blk {
		padding: 22px 0px;
	}
	.perks-benefit-box {
		width: 95%;
	}
	.con-space-wr {
		padding: 44px 10px 34px;
	}
	.con-cross-bottom-wr.con-tab-list-wr h5{
		font-size: 21px;
		line-height: 27px;
	}
	.con-cross-bottom-wr.con-tab-list-wr h5 {
		margin-bottom: 22px;
	}
	.testimonial-custom-modal div {
		width: 270px !important;
		height: 180px !important;
	}
	.header-contact-information .contact-details-block.mail_us a {
		width: 100%;
		margin-left: 0;
	}

	.circle {
		width: 241px;
		height: 241px;
	}
	.circle h1 {
		margin: 72px 0px 151px;
		font-size: 37px;
		line-height: 52px;
	}
	.diwali-decor img{
	left: 10px;	
	}
	.diwali-decor-2 img {
		left: auto;
		right: 10px;
	}
	.diwali-decor-2b img, .diwali-decor-2a img { left: auto; }
	.diwali-decor-1a img {
		left: 58px;
	}
	.diwali-decor-1b img {
		left: 110px;
	}
	.map-dots li:first-child:hover .location-bubble {
		top: -155px;
	}
	
.get-started-slide .swiper-button-prev, .get-started-slide .swiper-button-next  {
	top: 41%;
}
.con-about-img .con-overlay-title h3{
	color: #000;
}.con-about-img .con-head-blk {
    padding-bottom: 0px;
}
.con-anim-left-right-wr {
    padding-top: 0px;
}	.con-web-develop-design-wr .con-anim-left-right-wr .con-anim-left-wr {
    margin-bottom: 25px;
}
.industries-box figure {
    width: 134px;
}.form-checkbox-input label.twiceLabel:before{
	top: 8px;
} .con-jquery-wr h3 {
	background-position: 37% 50%;
}
.common-heading.anim {
	padding-bottom: 20px;
}	.loader-event-img.twice {
	width: 50%;
}.loader-counter-container {
    width: 97%;
}.numbers div, .counter-aniv {
    height: 74px;
}.loader-counter-container .counter-suffix {
    font-size: 25px;
}.loader-counter-container .counter-suffix {
    font-size: 19px;
}.counter-sub {
    font-size: 15px;
    line-height: 18px;
}
.banner-image-blk.aniversary figure:nth-child(1) {
    width: 89%;
}.marquee-container h3, .marquee-container h3 span {
	padding: 20px 0;
	line-height: 15px;
}.clinking-glass-img {
    width: 5.3%;
}
.marquee-container h3 span {
	padding: 0 4px;
}.loader-dot::before {
    transform: translate(35px,-34px);
}.animate-char-wr {
	bottom: 35px;
}
}
/*375*/
@media only screen and (max-width: 374px) {
	.loader-event-img.twice {
		width: 260px;
	}
	@keyframes scrollAnimation {
		0% {
		  left: -350px;
		  transform: translateX(0);
		}
		50% {
		  transform: translateX(200vw); 
		}51%{
		  transform: translateX(200vw) scaleX(-1); 
		}
		100% {
		  left: -350px;
		  transform: translateX(0)  scaleX(-1); 
		}
	  }
    .common-heading span:nth-child(2) {
		font-size: 31px;
		line-height: 33px;
		margin-top: 13px;
		margin-bottom: 13px;
    }

    .our-project-heading.left h3 span:nth-child(2) {
        font-size: 33px;
        line-height: 39px;
    }
    /*Contact Us*/
	.map-image-wr figure.map-image-blk img {
    	height: 349px;
	}
	.location-address p {
	    font-size: 11px;
	    line-height: 19px;
	}
	.location-bubble {
    	padding: 12px 3px;
	}
	.map-dots li:first-child .location-bubble {
	    top: -113px;
	    left: -40px;
	}
	.location-bubble {
    	width: 135px;
    	top: -201px;
	}
	.map-dots li:first-child .location-bubble {
	    top: -128px;
	    left: -42px;
	}
	.map-dots li:first-child {
    	left: 14%;
	}
	/* Portfolio */
	.post-grid-blk a {
		height: 132px;
	}
	.con-right-wr {
		width: 100%;
		margin: 30px 0px;
	}
	figure.testimonial-client-img {
		width: 169px;
		height: 258px;
		top: -124px;
		left: -73px;
		padding: 0;
	}
	.circle {
		width: 212px;
		height: 212px;
	}
	.circle h1 {
		margin: 58px 0px 151px;
		font-size: 33px;
		line-height: 46px;
	}
	.map-tabing:hover .location-bubble {
		top: -176px;
	}
	span.header-submenu-content p {
		line-height: 17px;
	}
	.common-heading.anim {
		padding-bottom: 5px;
	}.conative-christmas-logo-container {
		width: 220px;
	}#loader-content-wr h1 {
		font-size: 35px;
	}#loader-content-wr h1.loader-title {
		/* margin-bottom: -30px;
		margin-top: 10px; */
		padding: 5px 0;
	}#loader-content-wr h1.loader-title.twice{
		/* margin-bottom: 10px;
		margin-top: -40px; */
		padding: 5px 0;
	}.loader-event-img.twice {
		width: 50%;
		bottom: 20%;
	}
	.loader-counter-container {
		width: 100%;
	}.numbers div {
		font-size: 38px;
		line-height: 90px;
	}
	.loader-counter-container .counter-suffix {
		line-height: 80px;
	}.numbers div, .counter-aniv {
		height: 61px;
		width: 70px;
	}@keyframes countNumber {
		0% {
			margin-left:0px
		}
		100% {
			margin-left:-700px
		}
	  }.counter-sub{
		font-size: 14px;
	  }
	  .loader-dot::before {
		transform: translate(27px,-31px);
		height: 8px;
		width: 8px;
	}.animate-char-wr {
		bottom: 24px;
	}.marquee-container h3, .marquee-container h3 span {
		font-size: 8px;
	}.white-logo-img {
    width: 58%;
	}.text-image {
		font-size: 62px;
	}
}

/* 320 */