.holi-loading-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(45deg, #e984e4, #dcba33, #f55632, #6f46e9, #33aaff);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.small-logo {
    width: clamp(50px, 10vw, 100px);
    height: auto;
    background: transparent;
    filter: brightness(100%) contrast(100%) opacity(1);
}

.holi-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.holi-logo img {
    max-width: clamp(250px, 50vw, 500px);
    height: auto;
    animation: rotate-scale 2s infinite alternate;
}


@keyframes rotate-scale {
    0% {
        transform: rotate(0deg) scale(1);
    }

    100% {
        transform: rotate(10deg) scale(1.1);
    }
}


@media (max-width: 1024px) {
    .holi-logo img {
        max-width: clamp(200px, 45vw, 400px);
    }
}

@media (max-width: 768px) {
    .holi-logo img {
        max-width: clamp(180px, 40vw, 350px);
    }
}

@media (max-width: 480px) {
    .holi-logo img {
        max-width: 250px;
    }
}



.holi-text {
    font-size: clamp(2rem, 5vw, 6rem);
    font-family: cursive;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3),
        -2px -2px 10px rgba(255, 0, 0, 0.3),
        2px -2px 10px rgba(0, 255, 0, 0.3),
        -2px 2px 10px rgba(0, 0, 255, 0.3);
    max-width: 90%;
   
    word-wrap: break-word;
  
    line-height: 1.2;
  
    color: black;
}

@media (max-width: 400px) {
    .holi-text {
        font-size: 2rem;
       
        max-width: 80%;
       
        text-align: center;
       
    }
}


@keyframes bounce-in {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    50% {
        transform: translateY(10px);
        opacity: 0.7;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.bounce-in {
    animation: bounce-in 1.5s ease-in-out;
}


.color-splash {
    position: absolute;
    width: clamp(200px, 30vw, 400px);
    height: clamp(200px, 30vw, 400px);
    background: radial-gradient(circle, rgba(255, 0, 150, 0.7), rgba(255, 255, 0, 0.6), rgba(0, 255, 150, 0.7));
    border-radius: 50%;
    animation: splash 2s infinite alternate;
    opacity: 0.8;
}

@keyframes splash {
    0% {
        transform: scale(0.8);
        opacity: 0.7;
    }

    100% {
        transform: scale(1.2);
        opacity: 1;
    }
}
